import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupText,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  FormFeedback 
} from "reactstrap";
import {
  AutoSave,
  WebNotification,
  DynamicModelService,
  EventService,
} from "../components/jwt/_services";
import ctzc from "country-tz-currency";
import dateFormat from "dateformat";
import { ProjectSettingsService } from "../components/jwt/_services/ProjectSettings.service";
import * as Url from "../../src/services/ServiceConstant";
import axios from "axios";
import apiRequest from "../services/ServiceRequest";
import { AuthHeader, HandleResponse } from "../components/jwt/_helpers";
import { constants } from "../components/jwt/_helpers/constants";
import Header from "./header";
import Sidebar from "./menu";
import "./css/superAdmin.css";
import { useForm } from "react-hook-form";
import logodarkicon from "../../src/assets/img/logo-light-icon.png";
import Axios from "axios";
import Toggle from "react-toggle";
import SMSSettings from "./SMSSettings";
import SMTPSettings from "./SMTPSettings";
import APIKeySettings from "./APIKeySettings";
import { FaCamera } from "react-icons/fa";
import { getUser } from "../utils/common";
// import LoginScreen from "./logInScreen";
import { toast } from 'react-toastify';
import Customizer from "./superAdminTheamSetting";

const SuperAdmin = () => {
  toast.configure()
  var user = getUser();
  const [users, setUsers] = useState([]);
  const [PORT, setPORT] = useState("https://delapi.ng.jumpgrowth.com/");
  const [projectTitle, setProjectTitle] = useState("");
  const [projectWebsiteLink, setProjectWebsiteLink] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [multi_restaurant, setMultiRestaurant] = useState();
  const [selectedFile, setSelectedFile] = useState("");
  const [_id, setId] = useState();
  const [_idtls, setIdtls] = useState();
  const [previewImage, setPreview] = useState(logodarkicon);
  const [green_image, setGreenImage] = useState(logodarkicon);
  const [yellow_image, setYellowImage] = useState(logodarkicon);
  const [red_image, setRedImage] = useState(logodarkicon);
  const [businessName, setBusinessName] = useState("");
  const [corporateAddress, setCorporateAddress] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  // const [currency, setCurrency] = useState("");
  const [driverGreenImage, setDriverGreenImage] = useState("");
  const [driverRedImage, setDriverRedImage] = useState("");
  const [driverYellowImage, setDriverYellowImage] = useState("");
  const [googleSetting, setGoogleSetting] = useState({});
  const [paymentSetting, setPaymentSetting] = useState({});
  const [smsSetting, setSmsSetting] = useState({});
  const [supportEmail, setSupportEmail] = useState("");
  const [supportPhone, setSupportPhone] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  const [type, setType] = useState("smtp");
  const [current_password, setCurrentPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [googleApiKeyError, setGoogleApiKeyError] = useState("");

  // const [smtpSettings, setSmtpSettings] = useState({
  //   host: "",
  //   id: "",
  //   password: "",
  //   port: "",
  //   type: "",
  //   username: "",
  // });
  var [countries, setCountries] = useState([]);
  var [settings, setSettings] = useState({});
  var [timeZones, setTimezones] = useState(
    Object.keys(ctzc.getAllTimezones()).sort()
  );
  var [currencies, setCurrencies] = useState(
    Object.keys(ctzc.getAllCurrencies()).sort()
  );
  var [selectedFormat, updateFormat] = useState("shortDate");
  var [selectedTimeFormat, updateSelectedTimeFormat] = useState("shortTime");
  var [selectedCurrency, updateCurrency] = useState("USD");
  var [selectedTimezone, updateTimezone] = useState("Asia/Kolkata");
  var [updateCountryCode, updateCountryCodeVal] = useState("");
  var [dateFormats, setDateFormats] = useState([
    "shortDate",
    "paddedShortDate",
    "mediumDate",
    "longDate",
    "fullDate",
  ]);
  var [timeFormats, setTimeFormats] = useState(["shortTime", "mediumTime"]);
  const [googleApiKey, setGoogleApiKey] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [stripe_secret, setStripeSecret] = useState("");
  const [error, setError] = useState('');
  const [websiteLinkError, setWebsiteLinkError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [stripe_secretError, setStripe_secretError] = useState('');


  /** SMTP Settings */
  useEffect(() => {
    getProjectSettings();
    // getProjectSettingsSmtp();
    getLocalization();
    loadGoogleSettings();
    loadPaymentSettings();
  }, []); // includes empty dependency array

  const loadPaymentSettings = function () {
    ProjectSettingsService.getPaymentSettings().then((data) => {
      if (!data.extracted.length) return;
      var settings = data.extracted[0];
      if (settings.id && settings.paymentSetting) {
        setPaymentMethod(settings.paymentSetting.type);
        setStripeSecret(settings.paymentSetting.stripe_secret || "");
      }
    });
  };

  const notify = (message) => {
    toast.success(message)
}
const errornotify = (message) => {
  toast.error(message)
}
  const handleChangeSecret = (e) => {
    setStripeSecret(e.target.value); // Update state with the new value
  };

  const onSubmitSecret = async () => {
    if (!stripe_secretError.trim()) {
      setStripe_secretError("This field is required.");
    } else {
      setStripe_secretError(''); // Clear error if the field is valid
    }
    const paymentSetting = { stripe_secret: stripe_secret }; // Structure it as needed
    try {
      ProjectSettingsService.updatePaymentSettings({ paymentSetting }).then(
        (updated) => {
          toast.success("Updated Successfully")
          // WebNotification.success(`Updated Successfully`, "", 1);
          loadPaymentSettings();
        }
      );
    } catch (error) {
      console.error("Failed to update Stripe secret:", error);
    }
  };

  const loadGoogleSettings = function () {
    ProjectSettingsService.getGoogleAPIKeySettings().then((data) => {
      if (!data.extracted.length) return;
      var settings = data.extracted[0];
      if (settings.id && settings.googleSetting) {
        setGoogleApiKey(settings.googleSetting.api_key);
      }
    });
  };

  const getLocalization = async () => {
    axios
      .get(`${Url.BACKEND_ROOT}api/projectSettings`)
      .then((result) => {
        updateCurrency(result.data.extracted[0].currency);
        updateCountryCodeVal(result?.data?.extracted[0]?.countryCode);
        updateFormat(result.data.extracted[0].dateFormat);
        updateSelectedTimeFormat(result.data.extracted[0].timeFormat);
        ProjectSettingsService.locale.getCountries().then((countriess) => {
          setCountries(countriess);
          console.log(countries, "updateCountryCodeVal");
        });
        ProjectSettingsService.getAll().then((countries) => {
          setSettings(countries.extracted[0]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
 const getProjectSettings = async () => {
    try {
      const response = await fetch(
        `${Url.BACKEND_ROOT}api/projectSettings`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const projectSettings = await response.json();
      const { extracted } = projectSettings;
      if (extracted.length > 0) {
        const settings = extracted[0];
        setProjectTitle(settings.projectTitle,);
        setProjectWebsiteLink(settings.projectWebsiteLink);
        setShortDescription(settings.shortDescription);
        console.log("settings.multi_restaurant",settings.multi_restaurant)
        setMultiRestaurant(settings.multi_restaurant);
        setBusinessName(settings.business_name);
        setCorporateAddress(settings.corporate_address);
        setCountry(settings.country);
        setCountryCode(settings.countryCode);
        // setCurrency(settings.currency);
        setDriverGreenImage(settings.driver_green_image);
        setDriverRedImage(settings.driver_red_image);
        setDriverYellowImage(settings.driver_yellow_image);
        setGoogleSetting(settings.googleSetting);
        setPaymentSetting(settings.paymentSetting);
        setSmsSetting(settings.smsSetting);
        setSupportEmail(settings.support_email);
        setSupportPhone(settings.support_phone);
        setTimeFormat(settings.timeFormat);
        if (settings.logo !== "N/A") {
          setPreview(settings.logo);
          setSelectedFile(settings.logo);
          setGreenImage(settings.driver_green_image);
          setYellowImage(settings.driver_yellow_image);
          setRedImage(settings.driver_red_image);
        } else {
          setPreview(logodarkicon);
        }

        setId(settings.id);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const fileUploadHandler = async (data, color) => {
    const formData = new FormData();
    formData.append("file", data);
    const res = await fetch("https://delapi.ng.jumpgrowth.com/api/v1/upload", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());
    var logo = "https://delapi.ng.jumpgrowth.com/" + res.file;
    Axios.post("https://delapi.ng.jumpgrowth.com/api/projectSettings/logo", {
      logo: logo,
    })
      .then((settings) => {
        console.log(settings);
      })
      .catch((err) => {
        console.log(err);
      });
    window.location.reload();
  };

  const driverImageFileHandler = async (data, color) => {
    const formData = new FormData();
    formData.append("file", data);
    const res = await fetch("https://delapi.ng.jumpgrowth.com/api/v1/upload", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());
    var image = "https://delapi.ng.jumpgrowth.com/" + res.file;
    if (color == "green") {
      Axios.post(
        "https://delapi.ng.jumpgrowth.com/api/projectSettings/driver_green_image",
        {
          driver_green_image: image,
        }
      )
        .then((settings) => {
          setGreenImage(image);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (color == "yellow") {
      Axios.post(
        "https://delapi.ng.jumpgrowth.com/api/projectSettings/driver_yellow_image",
        {
          driver_yellow_image: image,
        }
      )
        .then((settings) => {
          setYellowImage(image);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (color == "red") {
      Axios.post(
        "https://delapi.ng.jumpgrowth.com/api/projectSettings/driver_red_image",
        {
          driver_red_image: image,
        }
      )
        .then((settings) => {
          setRedImage(image);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    window.location.reload();
  };

  const { register, handleSubmit, control, errors } = useForm({
    mode: "onBlur",
  });


  const onSubmit = async (e) => {
    const formData = new FormData();
    if (_id) formData.append("id", _id);
    const mr = multi_restaurant === false ? true : false;
    formData.append("logo", selectedFile);
    formData.append("projectTitle", projectTitle);
    formData.append("projectWebsiteLink", projectWebsiteLink);
    formData.append("shortDescription", shortDescription);
    formData.append("multi_restaurant", mr);

    try {
      await fetch(`${Url.BACKEND_ROOT}api/projectSettings`, {
        method: "POST",
        body: formData,
      });

      setTimeout(() => {
        // WebNotification.success(
        //   _id ? "Record updated successfully" : "Record added successfully"
        // );
        toast.success( _id ? "Record updated successfully" : "Record added successfully"
      )
        EventService.triggerEvent("navigation", {});
        getProjectSettings(); // Call getProjectSettings to refresh data
      }, 1000);
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const validateFields = () => {
    const errors = {
      titleError: !projectTitle.trim() ? 'Project title is required.' : '',
      descriptionError: !shortDescription.trim() ? 'Short description is required.' : '',
      websiteLinkError: !projectWebsiteLink.trim() ? 'Project website link is required.' : '',
    };
  
    setError(errors.titleError);
    setDescriptionError(errors.descriptionError);
    setWebsiteLinkError(errors.websiteLinkError);
  
    return !errors.titleError && !errors.descriptionError && !errors.websiteLinkError;
  };
  
  const submitForm = async () => {
    const formData = new FormData();
    if (_id) formData.append("id", _id);
    formData.append("logo", selectedFile);
    formData.append("projectTitle", projectTitle);
    formData.append("projectWebsiteLink", projectWebsiteLink);
    formData.append("shortDescription", shortDescription);
    formData.append("multi_restaurant", multi_restaurant);
  
    try {
      await fetch(`${Url.BACKEND_ROOT}api/projectSettings`, {
        method: "POST",
        body: formData
      });
  
      toast.success(_id ? "Record updated successfully" : "Record added successfully");
      EventService.triggerEvent("navigation", {});
      getProjectSettings(); // Refresh data
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const handleBlur = async () => {
    if (validateFields()) {
      await submitForm();
    }
  };
  

  const handleCheckboxChange = async(item) => {
    // const updatedMultiRestaurant = !multi_restaurant;
    let updatedMultiRestaurant;
    if (multi_restaurant == "false") {
      updatedMultiRestaurant = true;
    } else {
      updatedMultiRestaurant = false;
    }
    setMultiRestaurant(updatedMultiRestaurant);
    const formData = new FormData();
    if (_id) formData.append("id", _id);
    formData.append("logo", selectedFile);
    formData.append("projectTitle", projectTitle);
    formData.append("projectWebsiteLink", projectWebsiteLink);
    formData.append("shortDescription", shortDescription);
    formData.append("multi_restaurant", updatedMultiRestaurant);

    try {
      await fetch(`${Url.BACKEND_ROOT}api/projectSettings`, {
        method: "POST",
        body: formData
      });

      setTimeout(() => {
        // WebNotification.success(
        //   _id ? "Record updated successfully" : "Record added successfully"
        // );
        toast.success( _id ? "Record updated successfully" : "Record added successfully")
        EventService.triggerEvent("navigation", {});
        getProjectSettings(); // Call getProjectSettings to refresh data
      }, 1000);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  var updateCountry = (val) => {
    var country = countries.find((c) => c.name == val);
    updateTimezone(country.timezone);
    updateCurrency(country.currency);
    Server.updateCountry(val);
  };
  var setTimezone = (val) => {
    Server.updateTimezone(val).then(() => {
      updateTimezone(val);
    });
  };
  var showCustomFormat = () => {
    return (
      selectedFormat == "custom" || dateFormats.indexOf(selectedFormat) == -1
    );
  };
  const handleChangeApi = (e) => {
    setGoogleApiKey(e.target.value); // Update state with the new value
  };
  const onSubmitApi = () => {
    if (!googleApiKey.trim()) {
      setGoogleApiKeyError("This field is required.");
    } else {
      setGoogleApiKeyError(''); // Clear error if the field is valid
    }
    const googleSetting = { api_key: googleApiKey };
    ProjectSettingsService.updateGoogleAPIKeySettings({ googleSetting }).then(
      (updated) => {
        toast.success("Updated Successfully")
        // WebNotification.success(`Updated Successfully`, "", 1);
        loadGoogleSettings();
      }
    );
  };
  var updateCountryCode = (val) => {
    updateCountryCodeVal(val);
    Server.updateCountryCode(val);
  }

  var setCurrency = (val)=>{
    Server.updateCurrency(val).then(()=>{
      updateCurrency(val);
      axios.post('https://delapi.ng.jumpgrowth.com/api/update_currency', {
        currency : val
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    })
  }

  const Server = {
    updateCountry: (country) => {
      ProjectSettingsService.locale.setCountry({ country }).then(() => {
        toast.success("Country updated successfully")
        // WebNotification.success("Country updated successfully");
      });
    },
    updateCountryCodeVal: (countryCode) => {
      return ProjectSettingsService.locale
        .setCountryCode({ countryCode })
        .then(() => {
          toast.success("Country code updated successfully")
          // WebNotification.success("Country code updated successfully");
        });
    },

    updateCountryCode: (countryCode) => {
      return ProjectSettingsService.locale.setCountryCode({ countryCode }).then(() => {
        WebNotification.success('Country code updated successfully');
      })
    },

    updateTimezone: (timezone) => {
      return ProjectSettingsService.locale
        .setTimezone({ timezone })
        .then(() => {
          toast.success("Timezone updated successfully")
          // WebNotification.success("Timezone updated successfully");
        });
    },
    updateCurrency: (currency) => {
      return ProjectSettingsService.locale
        .setCurrency({ currency })
        .then(() => {
          toast.success("Currency updated successfully")
          // WebNotification.success("Currency updated successfully");
        });
    },
    updateDateFormat: (dateFormat) => {
      return ProjectSettingsService.locale
        .setDateFormat({ dateFormat })
        .then(() => {
          toast.success("Date Format updated successfully")
          // WebNotification.success("Date Format updated successfully");
        });
    },
    updateTimeFormat: (timeFormat) => {
      return ProjectSettingsService.locale
        .setTimeFormat({ timeFormat })
        .then(() => {
          toast.success("Time Format updated successfully")
          // WebNotification.success("Time Format updated successfully");
        });
    },
  };


  const ChangePassword = async (id, current_password, new_password, confirm_password) => {
    let url;
    const role = localStorage.getItem("role");
    if (role === "businessowner") {
        url = Url.BASE_URL + '/BusinessOwners/change-password';
    } else if (role === "superadmin") {
        url = Url.BASE_URL + '/SuperAdmins/change-password';
    } else {
        url = Url.BASE_URL + '/LocationManagers/change-password';
    }
    if (current_password == "" || new_password == "" || confirm_password == "") {
        errornotify("password fields are mandatory!!");
        return false;
    }
    if (new_password != confirm_password) {
        errornotify("new and confirm passwords do not match!!");
        return false;
    }
    let changePassword = await apiRequest({
        oldPassword: current_password,
        newPassword: new_password,
        role: role
    }, url, 'post');
    if (changePassword == undefined) {
        errornotify("old password is incorrect!!");
    } else {
        notify("password changed successfully!!");
    }
}
  const setPassword = async (text, type) => {
    if (type == "current") {
        setCurrentPassword(text);
    }
    else if (type == "new") {
        setNewPassword(text);
    }
    else if (type == "confirm") {
        setConfirmPassword(text);
    }
}
  const PasswordForm = () => {
    return (
        <div class="change_password">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="heading_settings">Change Password</h2>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Current Password</label>
                        <input type="password" value={current_password} onChange={(e) => setPassword(e.target.value, "current")} class="form-control" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>New Password</label>
                        <input type="password" value={new_password} onChange={(e) => setPassword(e.target.value, "new")} class="form-control" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Confirm New Password</label>
                        <input type="password" value={confirm_password} onChange={(e) => setPassword(e.target.value, "confirm")} class="form-control" />
                    </div>
                </div>

                <div class="col-md-12">
                    <button onClick={() => ChangePassword(user, current_password, new_password, confirm_password)} class="btn_sty_1">Change Password</button>
                </div>
            </div>
        </div>
    );
}
 
  return (
      <div>
      <div class="sticky">
        <Header />
      </div>
      <body>
        <div class="middle_block">
          <Sidebar tabvalue="staff" />
          <div class="main_content super_admin">
            <div class="container-fluid">
              <div class="settings_page">
                <h4>Project Settings</h4>
                <hr></hr>
                        {PasswordForm()}
                        <hr></hr>
                <h5>Meta Information</h5>
               
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Logo</label>
                     <div className="d-flex">
                     <img className="imagePreview mb-2 mr-3" 
                      src={previewImage}
                    // style={{ backgroundColor: 'grey',  width: '200px', height: '100px', border: '1px' }}
                    />
                      <InputGroup>
                        <div className="custom-file mt-4">
                          <Input
                            className="custom-file-input"
                            type="file"
                            name="file"
                            id="file"
                            accept="image/*"
                            placeholder="sample image"
                            onChange={(e) =>
                              fileUploadHandler(e.target.files[0], null)
                            }
                          />
                          <label className="custom-file-label" htmlFor="file">
                            Choose file
                          </label>
                        </div>
                      </InputGroup>
                     </div>
                    
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Project Title</label>
                      <Input
                        type="text"
                        className="form-control"
                        value={projectTitle}
                        onChange={(e) => setProjectTitle(e.target.value)}
                        onBlur={handleBlur}
                        invalid={!!error} // Mark input as invalid if there's an error
                      />
                      {error && <FormFeedback>{error}</FormFeedback>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label>Project Website Link</label>
                      <Input
                        type="text"
                        className="form-control"
                        value={projectWebsiteLink}
                        onChange={(e) => setProjectWebsiteLink(e.target.value)}
                        onBlur={handleBlur}
                        invalid={!!websiteLinkError}
                        />
                        {websiteLinkError && <FormFeedback>{websiteLinkError}</FormFeedback>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
          <div className="col-6 px-0">
            <div className="toggle_block">
              <h3>Multi Restaurant</h3>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={multi_restaurant == 'false' ? false : true}
                  value={multi_restaurant}
                  onChange={handleCheckboxChange}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </Col>
                  <Col md="6">
                    <FormGroup>
                      <label>Project Short Description</label>
                      <Input
                        type="textarea"
                        className="form-control"
                        value={shortDescription}
                        onChange={(e) => setShortDescription(e.target.value)}
                        onBlur={handleBlur}
                        invalid={!!descriptionError}
                        />
                        {descriptionError && <FormFeedback>{descriptionError}</FormFeedback>}
                    </FormGroup>
                  </Col>
              
                </Row>

                <hr></hr>
                <SMTPSettings/>
                <hr></hr>
                <h2 class="heading_settings mb-3">Localization Settings</h2>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup>
                        {/* <Input defaultValue={"Country"} readOnly disabled /> */}
                        <InputGroupText>Country</InputGroupText>
                        <InputGroupAddon addonType="append">
                          <select
                            id="country"
                            name="country"
                            onChange={(e) => updateCountry(e.target.value)}
                            className="form-control"
                          >
                            {countries.map((c, index) => (
                              <option
                                key={index}
                                selected={
                                  settings.country === c.name ? "selected" : ""
                                }
                                value={c.name}
                                name="country"
                              >
                                {c.name}
                              </option>
                            ))}
                          </select>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup>
                        {/* <Input
                          value="Country Code" // Static label for the input
                          readOnly
                          disabled
                        /> */}
                         <InputGroupText>Country Code</InputGroupText>
                        <InputGroupAddon addonType="append">
                        <select
                          id="countryCode"
                          // value={"+" + countryCode} // Set the selected value
                          onChange={(e) => updateCountryCode(e.target.value)}
                          className="form-control"
                          name="countryCode"
                        >
                          {countries.map((c, i) => (
                            <option
                              key={i} // Ensure each option has a unique key
                              value={"+" + c.phone}
                              selected={
                                countryCode == "+" + c.phone ? "selected" : ""
                              }
                            >
                              {"+" + c.phone}
                            </option>
                          ))}
                        </select>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup>
                        <InputGroupText>Default Timezone</InputGroupText>
                        <InputGroupAddon addonType="append">
                          <select
                            id="type"
                            name="type"
                            // value={settings.timezone}
                            onChange={(e) => setTimezone(e.target.value)}
                            className="form-control"
                          >
                            {timeZones.map((tz,i) => (
                              <option
                                key={i} // Use `tz` as the key if it's unique
                                value={tz}
                                selected= {settings.timezone == tz}
                                name="type"
                              >
                                {tz + " (" + ctzc.getTzById(tz).GMT + ")"}
                              </option>
                            ))}
                          </select>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup>
                        <InputGroup>
                          {/* <Input
                            value="Default Currency" // Static label for the input
                            readOnly
                            disabled
                          /> */}
                           <InputGroupText>Default Currency</InputGroupText>
                          <InputGroupAddon addonType="append">
                            <select
                              id="currency"
                              name="currency"
                              value={selectedCurrency} // Use selectedCurrency to set the current selection
                              onChange={(e) => setCurrency(e.target.value)}
                              className="form-control"
                            >
                              {currencies.map((c, index) => (
                                <option
                                  key={index} // Use index as a unique key if necessary
                                  value={c}
                                >
                                  {ctzc.getCurrencyByCode(c).name +
                                    " (" +
                                    ctzc.getCurrencyByCode(c).code +
                                    ")"}
                                </option>
                              ))}
                            </select>
                          </InputGroupAddon>
                        </InputGroup>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup>
                        {/* <Input
                          style={{ maxWidth: "200px" }}
                          defaultValue={"Date Format"}
                          readOnly
                          disabled
                        /> */}
                         <InputGroupText>Date Format</InputGroupText>
                        <InputGroupAddon addonType="append">
                          <select
                            id="type"
                            name={"type"}
                            value={selectedFormat} // Control the selected value using state
                            onChange={(e) => {
                              const value = e.target.value;
                              updateFormat(value);
                              Server.updateDateFormat(value);
                            }}
                            className="form-control"
                          >
                            {dateFormats.map((f, index) => (
                              <option key={index} value={f}>
                                {f}
                              </option>
                            ))}
                          </select>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup>
                        <Input
                          value={dateFormat(
                            new Date(
                              new Date().getTime() +
                                ctzc.getTzById(selectedTimezone).offset
                            ),
                            "custom" == selectedFormat
                              ? "default"
                              : selectedFormat
                          )}
                          readOnly
                          disabled
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup>
                        {/* <Input
                          style={{ maxWidth: "200px" }}
                          defaultValue={"Time Format"}
                          readOnly
                          disabled
                        /> */}
                         <InputGroupText>Time Format</InputGroupText>
                        <InputGroupAddon addonType="append">
                          <select
                            id="type"
                            name="type"
                            onChange={(e) => {
                              updateSelectedTimeFormat(e.target.value);
                              Server.updateTimeFormat(e.target.value);
                            }}
                            className="form-control"
                          >
                            {timeFormats.map((f, index) => (
                              <option
                                key={index}
                                selected={f === selectedTimeFormat}
                                value={f}
                                name="type"
                              >
                                {f}
                              </option>
                            ))}
                          </select>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup>
                        <Input
                          value={dateFormat(
                            new Date(
                              new Date().getTime() +
                                ctzc.getTzById(selectedTimezone).offset
                            ),
                            "custom" == selectedTimeFormat
                              ? "default"
                              : selectedTimeFormat
                          )}
                          readOnly
                          disabled
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>

                <hr></hr>
                <h2 class="heading_settings mb-3">Google API Key Settings</h2>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>API Key</label>
                      <InputGroup>
                        <InputGroupText>Key</InputGroupText>
                        <Input
                    type="api_key"
                    name='api_key'
                    id='api_key'
                    placeholder="api_key"
                    value={googleApiKey}
                    onChange={handleChangeApi} // Update state on change
                    onBlur={onSubmitApi} // Handle form submission on blur
                    invalid={!!googleApiKeyError}
                  />
                      </InputGroup>
                      {googleApiKeyError && <FormFeedback>{googleApiKeyError}</FormFeedback>}
                    </FormGroup>
                  </Col>
                </Row>

                <hr></hr>
                <h2 class="heading_settings mb-3">Payment Settings</h2>
                <Row>
                  <Col md="12">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md="12">
                          <Label>{"Payment Method: " + paymentMethod}</Label>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>Client Secret</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                type="text"
                                name="stripe_secret"
                                id="stripe_secret"
                                placeholder="stripe_secret"
                                value={stripe_secret}
                                onChange={handleChangeSecret} // Update state on change
                                onBlur={onSubmitSecret}
                                invalid={!!stripe_secretError}
                              />
                            </InputGroup>
                                 {stripe_secretError && <FormFeedback>{stripe_secretError}</FormFeedback>}
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>

                <hr></hr>
                <SMSSettings />
                <APIKeySettings />

                <Card>
                  <CardTitle className="mb-0 p-3 border-bottom bg-light">
                    <i className="mdi mdi-settings mr-2"></i>
                    <h2 class="heading_settings mb-3">Driver Images Settings</h2>
                  </CardTitle>
                  <CardBody className="DriverImagesSettings_card">
                    <div className="row">
                      <div className="col-md-4">
                        {/* GREEN IMAGE */}
                        <div className="form-group">
                          <label htmlFor="green-image">
                            GREEN IMAGE
                            <div className="img-download">
                              <img
                                className="imagePreview"
                                src={green_image}
                                alt="Green"
                                style={{
                                  backgroundColor: "grey",
                                  width: "200px",
                                  height: "100px",
                                  border: "1px solid black",
                                }}
                              />
                            </div>
                          <FaCamera style={{ color: "white" }} />
                          </label>
                          <input
                            id="green-image"
                            style={{ marginLeft: "20px" }}
                            type="file"
                            onChange={(e) =>
                              driverImageFileHandler(e.target.files[0], "green")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        {/* YELLOW IMAGE */}
                        <div className="form-group">
                          <label htmlFor="yellow-image">
                            YELLOW IMAGE
                            <div className="img-download">
                              <img
                                className="imagePreview"
                                src={yellow_image}
                                alt="Yellow"
                              />
                            </div>
                          <FaCamera style={{ color: "white" }} />
                          </label>
                          <input
                            id="yellow-image"
                            style={{ marginLeft: "20px" }}
                            type="file"
                            onChange={(e) =>
                              driverImageFileHandler(
                                e.target.files[0],
                                "yellow"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        {/* RED IMAGE */}
                        <div className="form-group">
                          <label htmlFor="red-image">
                            RED IMAGE
                            <div className="img-download">
                              <img
                                className="imagePreview"
                                src={red_image}
                                alt="Red"
                              />
                            </div>
                          <FaCamera style={{ color: "white" }} />
                          </label>
                          <input
                            id="red-image"
                            style={{ marginLeft: "20px" }}
                            type="file"
                            onChange={(e) =>
                              driverImageFileHandler(e.target.files[0], "red")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                <CardTitle className="mb-0 p-3 border-bottom bg-light">
                    <i className="mdi mdi-settings mr-2"></i>
                    <h2 class="heading_settings mb-3">Admin Theme Settings</h2>
                  </CardTitle>
                  <CardBody>
                    <Customizer/>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </body>
      </div>
  );
};

export default SuperAdmin;
