import Moment from 'react-moment';
import { Modal, Dropdown } from 'react-bootstrap';
const OrdersList = (props) => {
    const driverstatusTag = {
        "border": "1px solid #cccccc",
        "width": "200px",
        "text-align": "center",
        "padding": "4px",
        "border-radius": "15px",
        "background": "#dfe7e226",
        "font-size": "medium",
        "float": "right",
        "margin-top": "15px"
    }
    return (
        <div>
            <div class="product_block">
                <div className="large-2">
                    <div>
                        {
                            props?.values?.filtered_orders?.length > 0
                                ? props?.values?.filtered_orders?.map((item, index) => {
                                    return (
                                        <div key={index} class="r_order_box">
                                            <div class="r_order_top">
                                                <div class="img_box">{(item.customer != null) ? <img src={item.customer?.profile_image ? item.customer?.profile_image : './img-placeholder.png'} alt="" /> : <img src={props.values.category1} alt="" />}</div>
                                                <div class="r_order_info">
                                                    <div class="row">
                                                        <div class="col-md-4" onClick={() => { props.values.handleShow(item, "order") }}>
                                                            <h2 class="r_name">{item?.customer?.first_name == undefined ? item?.customer?.firstname : item?.customer?.first_name}</h2>
                                                            <h3>{(item.type_of_order != null) ? 'Type of Order : ' + item.type_of_order.charAt(0).toUpperCase() + item.type_of_order.slice(1) : "Delivery type not found"}</h3>
                                                            <h4>Address: <span>{(item.address != null) ? item.address.text : item?.location?.name}</span></h4>
                                                            <h4>Order ID <span>{item.orderNo}</span></h4>
                                                        </div>
                                                        {item?.type_of_order == "delivery" ? <div class="col-md-4">
                                                            <div class="img_box">{(item.customer != null && item.dropoff_image != undefined && item.dropoff_image != '') ? <img src={item?.dropoff_image ? item?.dropoff_image : './img-placeholder.png'} alt="" /> : ''}</div>

                                                        </div> : <div class="col-md-4"></div>}

                                                        <div class="col-md-4 r_align_right">
                                                            <h3 class="price">{(item.orderDetails != null && item.order_total != null) ? props.values.global_currency + item.order_total.toFixed(2) : props.values.global_currency + "o"}
                                                            </h3>
                                                            <h4>No.of Items: <span>{(item.orderDetails != null && item.orderDetails != null) ? item.orderDetails.length : "0"}</span></h4>
                                                            {((item.status?.order_status[0]) != "Cancelled" && item.type_of_order == "delivery" && (item.status?.order_status[0]) != "Delivered" || item?.driver_side_status == "picked") ?
                                                                <div class="custom_checkbox">
                                                                    <label class="check_container">Select
                                                                        <input type="checkbox" onChange={e => props.values.handleCheckbox(item, index)} />
                                                                        <span class="checkmark" ></span>
                                                                    </label>
                                                                </div> : ''}
                                                            {item?.status?.order_status[0] == 'Delivered' ? <p style={driverstatusTag}>Delivered By : {item.drivers?.first_name}</p> :
                                                                <>
                                                                    {item.type_of_order == "delivery" && item.driver_side_status != "rejected" ? <p style={driverstatusTag}>Assigned To : {item.drivers?.first_name}</p> : ""}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="r_order_bottom">
                                                {
                                                    (item.ordered_date != null && item.ordered_date != undefined) ?
                                                        <div class="r_dateandtime"><Moment format={props.values.global_date ? props.values.global_date : " DD MMM  YYYY-h:mm A"}>{item.ordered_date}</Moment></div> : "30 Dec 2020 - 12:30 PM"
                                                }
                                                {((item?.status?.order_status[0]) != "Delivered" && (item?.status?.order_status[0]) != "Cancelled") ? <div class="r_eta">
                                                    <a href="#" data-toggle="modal" data-target="#change_etaModal" onClick={() => { props.values.handleShow(item, "eta") }}>ETA: <span>{item.eta}</span> <i class="icofont-pencil-alt-2"></i></a>
                                                </div> : ''}
                                                <div class="r_status dropdown">
                                                <div className="status_selected" style={{ color: (item.status && item.status.order_status[0] === 'Cancelled') ? 'red' : 'inherit' }}>
                                                          {(item.status != null) ? item.status.order_status[0] : null}
                                                        <Dropdown onSelect={e => props.values._onChangeDropdown('value', e, item.id)}>
                                                            <Dropdown.Toggle as={props.values.CustomToggle} />
                                                            <Dropdown.Menu size="sm" title={(item.status != null) ? item.status.order_status[0] : null} value={props.values.status_value} onSelect={e => props.values._onChangeDropdown('value', e)}>
                                                                {/* <Dropdown.Header>Options</Dropdown.Header> */}
                                                                <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                                                                <Dropdown.Item eventKey="Accepted">Accepted</Dropdown.Item>
                                                                <Dropdown.Item eventKey="Cancelled" style={{ color: 'red' }}>Cancelled</Dropdown.Item>
                                                                <Dropdown.Item eventKey="Preparing">Preparing</Dropdown.Item>
                                                                <Dropdown.Item eventKey="Prepared">Prepared</Dropdown.Item>
                                                                <Dropdown.Item eventKey="Picked Up">Picked Up</Dropdown.Item>
                                                                {/* <Dropdown.Item disabled eventKey="On The Way">On The Way</Dropdown.Item> */}
                                                                <Dropdown.Item disabled eventKey="Delivered">Delivered</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{props.values.view_show_msg}</div>
                        }
                    </div>
                </div>
            </div>
            <div class="assign_driver_btn_wrap text-center">
                <a href="#" class="btn_sty_1" style={{ "min-width": "190px;" }} onClick={() => props.values.handleAssignDriverShow()}>Assign Driver</a>
            </div>
        </div>
    );
}
export default OrdersList;