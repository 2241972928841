import { useEffect, useState } from "react";
import apiRequest from "../services/ServiceRequest";
import Header from "./header";
import Sidebar from "./menu";
import * as Url from '../../src/services/ServiceConstant';
import Select from "react-select";
import { toast } from 'react-toastify';
import { handleLocationChange } from "../utils/common.js";
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import category1 from '../assets/img/category1.jpg';
import * as EmailTemplates from '../utils/emailtemplates.js';
import { createFor } from "typescript";
const CreateProduct = (props) => {
    toast.configure()
    var method = ""
    var update = ""
    const [view_show_msg, show_mesage] = useState("");
    var product_image = []
    const [categories, setCategories] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [locationId, setLocationId] = useState("");
    const [locations, setLocationData] = useState(null);
    const [locationvalue, setLocationValue] = useState([]);
    const [edits, setEdit] = useState(false);
    const [name, setName] = useState("");
    const [priceId, setPriceId] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [productId, setProductId] = useState("");
    const [viewNameError, setNameError] = useState(false);
    const [viewDescriptionError, setDescriptionError] = useState(false);
    const [viewCategoryError, setCategoryError] = useState(false);
    const [viewLocationError, setLocationError] = useState(false);
    const [viewProductError, setProductError] = useState(false);
    const [viewPriceError, setPriceError] = useState(false);
    const [viewImageError, setImageError] = useState(false);
    const [view_location_error, location_error] = useState("");
    const [view_name_error, name_error] = useState("");
    const [view_description_error, description_error] = useState("");
    const [view_category_error, category_error] = useState("");
    const [view_image_error, image_error] = useState("");
    const [view_price_error, price_error] = useState("");
    const [view_product_error, product_error] = useState("");
    const [images, setImage] = useState([]);
    const [productImages, setProductImage] = useState();
    const [selectedLocations, setSelectedLocations] = useState("");
    const [ID, setId] = useState("");
    const { state } = props.location
    const onEdit = async (state) => {
        setEdit(true)
        let endpoints = Url.BASE_URL + `/Products/${state}?filter={"include":["location","categories","pricings","addOns"]}`
        var get_staff = await apiRequest([], endpoints, 'get')
        if (get_staff?.data === '{}' || get_staff?.data == null || get_staff?.data == undefined) {
            show_mesage("No records found")
        }
        else {
            let images = []
            let temp_Product = get_staff?.data;
            setId(temp_Product.id)
            setName(temp_Product.name)
            setDescription(temp_Product.description)
            setProductId(temp_Product.productId)
            setCategoryId(temp_Product.categoryId)
            setPriceId(temp_Product.pricings[0].id)
            setPrice(temp_Product.pricings[0].unit_price)
            images.push(temp_Product.image)
            setImage(images)
            var locationArr = [];
            var location = [];
            for (var i = 0; i < temp_Product.location.length; i++) {
                if (temp_Product.location[i].id_deleted == false) {
                    var locationObj = {
                        value: temp_Product.location[i].id,
                        label: temp_Product.location[i].name
                    }
                    locationArr.push(locationObj);
                    location.push(temp_Product.location[i].id);
                }
            }
            setLocationValue(locationArr);
            setLocationId(location);
        }
    }
    let history = useHistory();
    useEffect(async () => {
        if (state != null && state != "" && state != undefined) {
            onEdit(state)
        }
        var get_categories = await apiRequest([], Url.CATEGORY + '?filter={"where":{"id_deleted":' + false + '}}', 'get')
        if (get_categories?.data === '{}' || Object.entries(get_categories?.data).length === 0 || get_categories == null || get_categories == undefined || get_categories?.length <= 0 || get_categories?.data?.length <= 0) {
            show_mesage("No records found")
        }
        else {
            let temp_Product = get_categories?.data;
            temp_Product.unshift({ id: 0, name: "Select Category", value: "" })
            setCategories(temp_Product)
        }
        var get_locations = await apiRequest([], Url.ADDLOCATION + '?filter={"where":{"id_deleted":' + false + '}}', 'get')
        if (get_locations?.data === '{}' || Object.entries(get_locations?.data).length === 0 || get_locations == null || get_locations == undefined || get_locations?.length <= 0 || get_locations?.data?.length <= 0) {
        }
        else {
            let temp_Array = get_locations?.data;
            // temp_Array.unshift({ id: 0, name: "Select Location", value: "" })
            temp_Array.unshift()
            let location = []
            // location.push({
            //     label: `Select ${localStorage.getItem("multi_restaurant") == 'true' ? 'Restaurant' : 'Location'}`,
            //     value: '',
            //     disabled: true
            // });
            if (localStorage.getItem("role") == "locationmanager") {
                var locations_assigned = localStorage.getItem("locations").split(',');
                for (var i = 0; i < locations_assigned.length; i++) {
                    for (var j = 0; j < temp_Array.length; j++) {
                        if (locations_assigned[i] == temp_Array[j].id) {
                            location.push({
                                value: temp_Array[j].id,
                                label: temp_Array[j].name
                            });
                        }
                    }
                }
            } else {
                temp_Array.forEach(function (post, i) {
                    let s = {
                        value: post.id,
                        label: post.name
                    }
                    location.push(s)
                });
            }
            setLocationData(location)
            if (selectedLocations.length > 0) {
                let temp = []
                temp = selectedLocations
                let sp = []
                let locationId = []
                temp.forEach(function (post, i) {
                    let s = {
                        value: post.id,
                        label: ""
                    }
                    sp.push(s)
                })
                var tempFav = sp;
                var product = locations;
                product.forEach(productItem => {
                    tempFav.forEach(favItem => {
                        if (productItem.value === favItem.value) {
                            favItem.label = productItem.label;
                        }
                    });
                });
                setLocationValue(sp)
                setLocationId(locationId)
            }
        }
    }, []);
    const _onChangeText = (type, text) => {
        if (type === 'assigned_category') {
            if (text !== '') {
                setCategoryId(text)
                category_error('')
                setCategoryError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                setCategoryId(text)
                category_error('Please enter  category')
                setCategoryError(false)
            }
        }
        if (type === 'name') {
            setName(text)
            if (text !== '') {
                setName(text)
                name_error("")
                setNameError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                name_error('Please enter Name')
                setNameError(false)
            }
        }
        if (type === 'description') {
            setDescription(text)
            if (text !== '') {
                setDescription(text)
                description_error("")
                setDescriptionError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                description_error('Please enter description')
                setDescriptionError(false)
            }
        }
        if (type === 'price') {
            if (text < 0) {
                alert("Negative values are not allowed!!");
                setPrice("");
                return false;
            }
            setPrice(text)
            if (text !== '') {
                setPrice(text)
                price_error("")
                setPriceError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                price_error('Please enter description')
                setPriceError(false)
            }
        }
        if (type === 'productId') {
            if (text < 0) {
                alert("Negative values are not allowed!!");
                setProductId("");
                return false;
            }
            setProductId(text)
            if (text !== '') {
                setProductId(text)
                product_error("")
                setProductError(true)
            }
            else if (text === '' || text === undefined || text === null) {
                product_error('Please enter product Id')
                setProductError(false)
            }
        }
    }
    const notify = (message) => {
        toast.success(message)
    }
    const errornotify = (message) => {
        toast.error(message)
    }
    const _onChangeImage = async (images) => {
        if (images == null || images == undefined || images == [] || images == '' || images == {}) {
            image_error('Please select image ')
            setImageError(false)
        }
        else {
            if (images.type != "image/jpeg" && images.type != "image/jpg" && images.type != "image/png") {
                image_error("Please upload the following file types: jpeg, and png.");
                return false;
            }
            var _size = images.size;
            if (_size > 1000000) {
                image_error("The size limit for images is 1MB. Reduce the file size and try again.");
                return false;
            }
            image_error(' ')
            setImageError(true)
            let formData = new FormData();
            formData.append('images', images);
            let image_response = await apiRequest(formData, Url.UPLOAD_IMAGE, 'post')
            if (image_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            }
            setProductImage(Url.image_url + image_response.data.images)
            setImage(Url.image_url + image_response.data.images)
        }
    }
    const onSubmit = async () => {
        if (edits == false) {
            var product = await apiRequest({}, Url.BASE_URL + '/Products?filter={"where":{"productId":' + productId + '}}', 'get');
            if (product?.data?.[0]?.productId == productId) {
                alert("Product Id already exists, please enter unique product Id!!");
                return false;
            }
        }
        if (name == "" || price == "" || productId == "" || description == "" || categoryId == "" || images.length <= 0) {
            setName(name)
            if (name !== '') {
                name_error('')
                setNameError(true)
            }
            else if (name === '' || name === undefined || name === null) {
                name_error('Please enter  name')
                setNameError(false)
            }
            setPrice(price)
            if (price !== '') {
                price_error('')
                setPriceError(true)
            }
            else if (price === '' || price === undefined || price === null) {
                price_error('Please select price')
                setPriceError(false)
            }
            setProductId(productId)
            if (productId !== '') {
                product_error('')
                setProductError(true)
            }
            else if (productId === '' || productId === undefined || productId === null) {
                product_error('Please enter product Id')
                setProductError(false)
            }
            setDescription(description)
            if (description !== '') {
                description_error('')
                setDescriptionError(true)
            }
            else if (description === '' || description === undefined || description === null) {
                description_error('Please enter description')
                setDescriptionError(false)
            }
            setLocationId(locationId)
            if (locationId != null && locationId == undefined && locationId.length > 0) {
                location_error('')
                setLocationError(true)
            }
            else if (locationId === '' || locationId === undefined || locationId === null || locationId.length <= 0) {
                location_error('Please select location')
                setLocationError(false)
            }
            setImage(images)
            if (images != null && images == undefined && images.length > 0) {
                image_error('')
                setImageError(true)
            }
            else if (images === '' || images === undefined || images === null || images.length <= 0) {
                image_error('Please select image')
                setImageError(false)
            }
            setCategoryId(categoryId)
            if (categoryId != null && categoryId != undefined && categoryId != "") {
                category_error('')
                setCategoryError(true)
            }
            else if (categoryId === '' || categoryId === undefined || categoryId === null) {
                category_error('Please select category')
                setCategoryError(false)
            }
        }
        else {
            if(!locationId.length){
                location_error('Please select location')
                setLocationError(false)
                return false
            }

            if (productId <= 0 || productId > Math.floor(productId)) {
                product_error('Please enter valid product Id')
                setProductError(false);
                return false;
            }
            var data_category = {
                "name": name,
                "productId": productId,
                "description": description,
                "categoryId": categoryId,
                "enable": true,
                "image": images,
                "location":locationvalue
            }
            try {
                if (edits === false) {
                    method = 'post'
                    update = 'Added successfully'
                    data_category.enable = true
                }
                else if (edits === true) {
                    method = 'patch'
                    data_category.id = ID
                    update = 'Updated successfully'
                }
                data_category.id_deleted = false;
                let staff_response = await apiRequest(data_category, Url.PRODUCTS, method)
                if (staff_response.error) {
                    errornotify("'Failed', response.error.message");
                    return;
                } else {
                    let throughmodel
                    let pricemodel
                    throughmodel = {
                        "productId": staff_response.data.id,
                        "locationId": locationId
                    }
                    pricemodel = {
                        "productId": staff_response.data.id,
                        "unit_price": price,
                    }
                    if (priceId != "") {
                        pricemodel.id = priceId
                    }
                    await apiRequest(pricemodel, Url.PRODUCTPRICING, 'patch')
                    let location_response = await apiRequest(throughmodel, Url.PRODUCTLOCATION, 'post')
                    if (location_response.error) {
                        errornotify("'Failed', response.error.message");
                        return;
                    } else {
                        notify(update)
                        setName("");
                        setPrice("")
                        setLocationId("")
                        setDescription("")
                        setProductId("")
                        setCategoryId("")
                        setImage("");
                        setId("")
                        setEdit(false)
                        var locationNames = locationvalue.map(function (val) {
                            return val.label;
                        }).join(',');
                        var locationmanagername = localStorage.getItem("username");
                        if (localStorage.getItem("role") == "locationmanager") {
                            await apiRequest({
                                title: `Location manager ${locationmanagername} has added ${name} product to ${locationNames} locations`,
                                message: `Location manager ${locationmanagername} has added ${name} product to ${locationNames} locations`,
                                type: "web",
                                role: "businessowner",
                                status: "open",
                                datetime: new Date().toISOString()
                            }, Url.BASE_URL + '/Notifications', 'post');
                        }
                        if (localStorage.getItem("role") == "locationmanager") {
                            // await EmailTemplates.productUpdated_LocationManager({
                            //     location_manager_name: locationmanagername,
                            //     location_name: locationNames,
                            //     product_name : name,
                            //     product_id : productId,
                            //     product_price : price,
                            //     businessOwnerId : localStorage.getItem("user").split('"').join('')
                            // });
                        }
                        if (edits == false) {
                            history.push('/product')
                        } else {
                            var pageNumber = props.location.pageNumber ? props.location.pageNumber : 1;
                            history.push('/product/' + pageNumber);
                        }
                        
                    }
                }
            }
            catch (err) {
                errornotify('Failed');
            }
        }
    }

    const handleCallback = async (childData) => {
    }
    return (
        <div>
            <html lang="en">
                <div class="sticky">
                    <Header parentCallback={handleCallback} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="product_page">
                                    <h2 class="head_sty1">Add new product</h2>
                                    <section class="staff_profile_sec">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-3">
                                                <div class="staff_profile_pic_box mng-profile">
                                                    <div class="staff_profile_img">
                                                        {
                                                            (images == '') ?
                                                                <img src='./img-placeholder.png' alt="" /> : <img src={images} alt="" />
                                                        }
                                                    </div>
                                                    <label class="custom-file-upload">
                                                        <input type="file" value={product_image} onChange={e => _onChangeImage(e.target.files[0])} />
                                                        Upload Image
                                                    </label>
                                                    {
                                                        (viewImageError) ?
                                                            <small></small> : <><small style={{ color: 'red' }}>{view_image_error}</small></>
                                                    }
                                                </div>
                                            </div>

                                            <div class="col-lg-8 col-md-9">
                                                <div class="staff_profile_form">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Product Name</label>
                                                                <input type="text" class="form-control" value={name} onChange={e => _onChangeText('name', e.target.value)} />
                                                                {(viewNameError) ?
                                                                    null : <><small style={{ color: 'red' }}>{view_name_error}</small></>}
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Product ID</label>
                                                                <input type="number" class="form-control" value={productId} onChange={e => _onChangeText('productId', e.target.value)} />
                                                                {
                                                                    (viewProductError) ?
                                                                        <small></small> : <><small style={{ color: 'red' }}>{view_product_error}</small></>}
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Select Category</label>
                                                                <div >
                                                                    <select class="form-control"
                                                                        value={categoryId}
                                                                        placeholder="Select category"
                                                                        onChange={e => _onChangeText('assigned_category', e.target.value)}
                                                                    >
                                                                        <option value={""} >Select</option>
                                                                        {categories.length > 0

                                                                            ? categories?.map((item, index) => {
                                                                                if (item.enable == true) {
                                                                                    return (
                                                                                        <option value={item.id} >{item.name}</option>
                                                                                    )
                                                                                }

                                                                            })
                                                                            : null
                                                                        }
                                                                    </select>
                                                                </div>
                                                                {
                                                                    (viewCategoryError) ?
                                                                        <small></small> : <><small style={{ color: 'red' }}>{view_category_error}</small></>}
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Price</label>
                                                                <input type="number" class="form-control" value={price} onChange={e => _onChangeText('price', e.target.value)} />
                                                                {
                                                                    (viewPriceError) ?
                                                                        <small></small> : <><small style={{ color: 'red' }}>{view_price_error}</small></>}
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label>Select {localStorage.getItem("multi_restaurant") == 'true' ? 'Restaurant' : 'Location'}</label>
                                                                <div style={{ "zIndex": 10 }}>
                                                                    <Select
                                                                        isMulti={true}
                                                                        value={locationvalue}
                                                                        onChange={(value) => handleLocationChange(value, locations,
                                                                            setLocationError,
                                                                            location_error,
                                                                            setLocationId,
                                                                            setLocationValue
                                                                        )}
                                                                        options={locations}
                                                                    />
                                                                </div>
                                                                {
                                                                    (viewLocationError) ?
                                                                        <small></small> : <><small style={{ color: 'red' }}>{view_location_error}</small></>}
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label>Description</label>
                                                                <textarea type="text" style={{ height: '100px' }} class="form-control" value={description} onChange={e => _onChangeText('description', e.target.value)} />
                                                                {(viewDescriptionError) ?
                                                                    null : <><small style={{ color: 'red' }}>{view_description_error}</small></>}
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group mb-0 staff_profile_btn_group">
                                                                <button type="submit" class="btn_sty_1" style={{ "min-width": "110px;" }} onClick={() => onSubmit()}>Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
        </div>
    )
}
export default CreateProduct;