import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setLogoBg, setNavbarBg, setSidebarBg, setTheme, setDir, setSidebarPos, setHeaderPos, setLayout, setSidebarType,
} from "../redux/settings/Action";
import { ThemeService,WebNotification} from '../components/jwt/_services';

/**
 * 
 *  
  activeThemeLayout: "vertical",
*/

const Customizer = function(props){
const [menuBg, setmenuBg] = useState('');
const [menuFont, setmenuFont] = useState('');
const [topHeaderBg, settopHeaderBg] = useState('');
const [topHeaderFont, settopHeaderFont] = useState('');
const [hoverBg,sethoverBg] = useState('');
const [highlightColor,sethighlightColor] = useState('');
const settings = useSelector((state) => state.settings);
const [customization, setCustomization] = useState({
    ...settings
  });
  const actions = {
    setTheme,
    setSidebarPos,
    setHeaderPos,
    setLayout,
    setSidebarType,
    setDir,
    setSidebarBg,
    setNavbarBg,
    setLogoBg
  }

  const dispatch = useDispatch();

  const changeHandler = (event) => {
    var value = event.target.value || event.target.dataset.value
    document.getElementById(event.target.id).value = event.target.value;
    if(value.includes("#")==false){
      dispatch(actions[event.target.dataset.dispatch](value));
    }
    customization[event.target.dataset.name]=value;
    setCustomization(customization);
    ThemeService.update(customization).then(res => {
      WebNotification.success(res.msg,"",1);
    });
    setTimeout(function(){
      window.location.reload();
    },2000);
  }

  useEffect(() => {
    ThemeService.getAll().then((res) => {
      setmenuBg(res.menuBg);
      setmenuFont(res.menuFont);
      settopHeaderBg(res.topHeaderBg);
      settopHeaderFont(res.topHeaderFont);
      sethoverBg(res.hoverBg);
      sethighlightColor(res.highlightColor);
    });
  }, []);


  return (
    <div className='Customizer' style={{ padding: '20px' }}>
    <br /><br />
    <div className="container" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
      <div className="form-group" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <label htmlFor="topHeaderBg"><b>Top header BG color:</b></label>
        <input
          onChange={changeHandler}
          data-name="topHeaderBg"
          data-dispatch="settopHeaderBg"
          id="topHeaderBg"
          value={topHeaderBg}
          type="color"
          style={{ marginLeft: '10px' }}
        />
      </div>
  
      <div className="form-group" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <label htmlFor="topHeaderFont"><b>Top header font color:</b></label>
        <input
          onChange={changeHandler}
          data-name="topHeaderFont"
          data-dispatch="settopHeaderFont"
          id="topHeaderFont"
          value={topHeaderFont}
          type="color"
          style={{ marginLeft: '10px' }}
        />
      </div>
  
      <div className="form-group" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <label htmlFor="menuBg"><b>Menu BG color:</b></label>
        <input
          onChange={changeHandler}
          data-name="menuBg"
          data-dispatch="setmenuBg"
          id="menuBg"
          value={menuBg}
          type="color"
          style={{ marginLeft: '10px' }}
        />
      </div>
  
      <div className="form-group" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <label htmlFor="menuFont"><b>Menu font color:</b></label>
        <input
          onChange={changeHandler}
          data-name="menuFont"
          data-dispatch="setmenuFont"
          id="menuFont"
          value={menuFont}
          type="color"
          style={{ marginLeft: '10px' }}
        />
      </div>
  
      <div className="form-group" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <label htmlFor="hoverBg"><b>Hover color:</b></label>
        <input
          onChange={changeHandler}
          data-name="hoverBg"
          data-dispatch="sethoverBg"
          id="hoverBg"
          value={hoverBg}
          type="color"
          style={{ marginLeft: '10px' }}
        />
      </div>
  
      <div className="form-group" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <label htmlFor="highlightColor"><b>Highlight color:</b></label>
        <input
          onChange={changeHandler}
          data-name="highlightColor"
          data-dispatch="sethighlightColor"
          id="highlightColor"
          value={highlightColor}
          type="color"
          style={{ marginLeft: '10px' }}
        />
      </div>
    </div>
  </div>
  
  );
};

export default Customizer;