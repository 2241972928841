import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
function LocationList(props) {
  let history = useHistory();
  const getLocationOrders = (id) => {
    history.push({
      pathname: "/orders",
      search: "?location=" + id,
      state: { id: id },
    });
  };
  const setRestaurantName = (restaurant_name) => {
    localStorage.removeItem("restaurant_name", restaurant_name);
    localStorage.setItem("restaurant_name", restaurant_name);
  };
  return (
    <div>
      {props.values.data.length > 0 ? (
        props.values.data?.map((item, index) => {
          return (
            <div class="m_staff_box location_box" key={item.id}>
              <div class="m_staff_top">
                <div class="img_box">
                  {item.images != null &&
                  item.images != undefined &&
                  item.images != "" ? (
                    <img src={item.images} alt="" />
                  ) : (
                    <img src="./img-placeholder.png" alt="" />
                  )}
                  ;
                </div>
                <div class="m_staff_info">
                  <div class="row">
                    <div class="col-md-6">
                      <h2 class="r_name">{item.name}</h2>
                    </div>
                    <div class="col-md-6 r_align_right view_id_btn_wrap">
                      <label class="switch">
                        {" "}
                        <input
                          type="checkbox"
                          disabled={false}
                          checked={item.enable}
                          value={item.enable}
                          onChange={(e) =>
                            props.values.handleCheckbox(item, index)
                          }
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="col-md-6">
                      <h4>
                        <span>Address:</span>{" "}
                        {item.address != null ? item.address.address : null}
                      </h4>
                    </div>
                    <div class="col-md-6 r_align_right">
                      <h4>
                        <span>Country:</span>{" "}
                        {item.address != null ? item.address.country : null}
                      </h4>
                    </div>
                    <div class="col-md-6">
                      <h4>
                        <span>City:</span>{" "}
                        {item.address != null ? item.address.city : null}
                      </h4>
                    </div>
                    <div class="col-md-6 r_align_right">
                      <h4>
                        <span>State:</span>
                        {item.address != null ? item.address.state : null}
                      </h4>
                      <h4>
                        <span>Contact:</span>
                        {item.address != null ? item.address.contact : null}
                      </h4>
                    </div>
                  </div>
                  <div class="m_staff_bottom">
                    <h4>
                      <span>Zip Code:</span>{" "}
                      {item.address != null ? item.address.zipcode : null}
                    </h4>
                    <div style={{ display: "flex" }} class="btn_group">
                      {item.orderscount > 0 ? (
                        <i>
                          No of orders :{" "}
                          <a
                            href="#"
                            onClick={(e) => getLocationOrders(item.id)}
                          >
                            {item.orderscount}
                          </a>{" "}
                          &nbsp;
                        </i>
                      ) : (
                        <i>
                          No of orders : <a>{item.orderscount}</a> &nbsp;
                        </i>
                      )}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {localStorage.getItem("multi_restaurant") == "true" ? (
                        <>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="black"
                              className='grey_btn'
                              style={{
                                "height": "45px",
                                "font-weight": "600",
                                "font-size": "16px",
                              }}
                              id="dropdown-basic"
                            >
                              Menu
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => setRestaurantName(item.name)}
                                href={`restaurant-categories/${item.id}`}
                              >
                                Categories
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setRestaurantName(item.name)}
                                href={`restaurant-products/${item.id}`}
                              >
                                Products
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          &nbsp;
                        </>
                      ) : (
                        ""
                      )}
                      <button
                        class="grey_btn"
                        data-toggle="modal"
                        data-target="#edit_locationModal"
                        onClick={(e) => props.values.onEdit(item)}
                      >
                        Edit
                      </button>
                      <button
                        class="grey_btn"
                        onClick={(e) => props.values.onDelete(item.id)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div align="center" style={{ "font-size": "16" }}>
          {props.values.view_show_msg}
        </div>
      )}
    </div>
  );
}
export default LocationList;
