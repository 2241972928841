import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import Reducers from "./Reducers";

// Ensure middleware is passed correctly
const store = createStore(
  Reducers,
  applyMiddleware(thunk) // Apply thunk middleware
);

export { store };