import { useState, useEffect } from "react";
import Header from "./header"
import Sidebar from "./menu"
import * as URL from '../../src/services/ServiceConstant';
import { Modal } from 'react-bootstrap';
import "../assets/css/pagination.css";
import apiRequest from "../services/ServiceRequest";
import { validateName } from '../helpers/validation';
import category1 from '../assets/img/category1.jpg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { filterLocations, LoaderGif, sortCategories, dynamicStylesheet } from "../utils/common.js"
import ResCategoriesList from "./list/restaurant_categories";
const ResCategories = (props) => {
    toast.configure();
    const [get_data, setCategories] = useState([]);
    const [show, setShow] = useState(false);
    const [images, setImage] = useState("");
    const [name, setName] = useState("");
    const [highlightColor, setHighLightColor] = useState('');
    const [labels, labelText] = useState("");
    const [ID, setID] = useState("");
    const [edits, setEdit] = useState(false);
    const [loading, setLoading] = useState(true);
    var method = ''
    var update = ''
    var categories_image = []
    const handleClose = () => {
        setShow(false);
        setName("");
        name_error("")
        setID("")
        image_error("")
    }
    const [view_name_error, name_error] = useState("");
    const [view_image_error, image_error] = useState("");
    const [view_show_msg, show_mesage] = useState("");
    const [viewNameError, setNameError] = useState(false);
    const [viewImageError, setImageError] = useState(false);
    const [enabless, setEnables] = useState(false);
    const getcategorydata = async () => {
        let endpoint = URL.CATEGORY + '?filter={"order":"id desc","where":{"id_deleted":' + false + '}}'
        var getcategory = await apiRequest([], endpoint, 'get')
        if (getcategory == null || getcategory == undefined || getcategory.data === '{}' || Object.entries(getcategory.data).length === 0 || getcategory === null || getcategory === undefined || getcategory.length <= 0 || getcategory.data.length <= 0) {
            show_mesage("No records found")
            setLoading(false);
        }
        else {
            var categories = await sortCategories(getcategory.data);
            setCategories(categories)
            setLoading(false);
        }
    }
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(20);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
    };
    const pages = [];
    for (let i = 1; i <= Math.ceil(get_data.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = get_data.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={handleClick}
                    className={currentPage == number ? "active" : null}
                >
                    {number}
                </li>
            );
        } else {
            return null;
        }
    });
    useEffect(async () => {
        // getcategorydata()
        let dynamicCSS = await dynamicStylesheet();
        setHighLightColor(dynamicCSS?.highlightColor);
        fetchCategories();
    }, []);
    const fetchCategories = async () => {
        var locationId = window.location.pathname.split("/").pop();
        var get_loc = await apiRequest({
            locationId : locationId
        }, URL.BASE_URL + '/restaurant-categories', 'post')
        if (get_loc == null || get_loc == undefined || get_loc.data === '{}' || Object.entries(get_loc.data).length === 0 || get_loc === null || get_loc === undefined || get_loc.length <= 0 || get_loc.data.length <= 0) {
            show_mesage("No records found")
            setLoading(false);
        }
        else {
            var categories = await sortCategories(get_loc.data);
            setCategories(categories)
            setLoading(false);
        }
    }
    const _onChangeImage = async (images) => {
        if (images == null || images == undefined || images == [] || images == '' || images == {}) {
            image_error('Please select image ')
            setImageError(false)
        }
        else {
            if (images.type != "image/jpeg" && images.type != "image/jpg" && images.type != "image/png") {
                image_error("Please upload the following file types: jpeg, and png.");
                return false;
            }
            var _size = images.size;
            if (_size > 1000000) {
                image_error("The size limit for images is 1MB. Reduce the file size and try again.");
                return false;
            }
            image_error('')
            setImageError(true)
            let formData = new FormData();
            formData.append('images', images);
            let image_response = await apiRequest(formData, URL.UPLOAD_IMAGE, 'post')
            if (image_response.error) {
                errornotify("'Failed', response.error.message");
                return;
            }
            setImage(URL.image_url + image_response.data.images)
        }
    }
    const handleShow = () => {
        labelText("Add Category")
        setImage("");
        setShow(true)
        setEdit(false)
    };
    const _onChangeText = (type, text) => {
        if (type === 'category_name') {
            if (text !== '') {
                setName(text)
                const _ValidateFirstName = validateName(text, 'category name');
                if (_ValidateFirstName['status']) {
                    name_error('')
                    setNameError(true)
                }
                else {
                    name_error(_ValidateFirstName['error'])
                    setNameError(false)
                }
            }
            else if (text === '' || text === undefined || text === null) {
                setName(text)
                name_error('Please enter  category name')
                setNameError(false)
            }
        }
    }
    const renderData = (data) => {
        return (
            (data.length > 0) ?

                <ResCategoriesList values={{
                    data: data,
                    get_data: get_data,
                    handleCheckbox: handleCheckbox,
                    onEdit: onEdit,
                    onDelete: onDelete,
                    view_show_msg: view_show_msg
                }} /> : <center><b>No Records found!!</b></center>
        );
    };
    const handleCheckbox = async (item, index) => {
        var enableData = '';
        if (item.multicategory.enable == true) {
            enableData = false;
            var windowconfirm = window.confirm('This will disable all products in this category. Are you sure you want to continue?');
        } else {
            enableData = true;
            var windowconfirm = window.confirm('Are you sure?');
        }
        apiRequest({
            id: item.multicategory.id,
            enable : enableData
        }, URL.BASE_URL + '/MultiCategories', 'patch');
        setTimeout(() => {
            window.location.reload();
        }, 300);
    }
    const onSubmit = async () => {
        if (name == '' || images == '' || images == null || images == undefined) {
            setName(name)
            const _ValidateFirstName = validateName(name, 'category name');
            if (_ValidateFirstName['status']) {
                name_error('')
                setNameError(true)
            }
            else {
                name_error(_ValidateFirstName['error'])
                setNameError(false)
            }
            if (images == null || images == undefined || images == [] || images == '' || images == {}) {
                image_error('Please select image ')
                setImageError(false)
            }
        }
        else {
            var data_category = {
                "name": name,
                "image": images,
                "type": "non",
            }
            if (name.length > 40) {
                alert("category name is too large, adjust it within 40 character limit!!");
                return false;
            }
            try {
                if (edits === false) {
                    method = 'post'
                    update = 'Added successfully'
                    data_category.enable = true
                    data_category.id_deleted = false
                }
                else if (edits === true) {
                    method = 'patch'
                    data_category.id = ID
                    update = 'Updated successfully'
                }
                let staff_response = await apiRequest(data_category, URL.BASE_URL + '/MultiCategories', method)
                if (staff_response.error) {
                    errornotify("'Failed', response.error.message");
                    return;
                } else {
                    var locationId = window.location.pathname.split("/").pop();
                    var multicategoryId = staff_response.data.id;
                    if (edits == false) {
                        let staff_response2 = await apiRequest({
                            locationId: locationId,
                            multicategoryId: multicategoryId
                        }, URL.BASE_URL + '/LocationCategories', 'post')
                    }
                    notify(update)
                    setShow(false)
                    setName("");
                    setImage("");
                    setID("")
                    setTimeout(() => {
                        window.location.reload();
                    }, 300);
                }
            }
            catch (err) {
                errornotify('Failed');
            }
        }
    }
    const onEdit = (edit) => {
        labelText("Edit category")
        setEdit(true)
        setShow(true)
        if (edit.multicategory.id != "") {
            setID(edit.multicategory.id)
        }
        if (edit.multicategory.name != "") {
            setName(edit.multicategory.name)
        }
        if (edit.multicategory.image != "") {
            setImage(edit.multicategory.image)
            setImageError(true)
        }
    }
    const notify = (message) => {
        toast.success(message)
    }
    const errornotify = (message) => {
        toast.error(message)
    }
    const onDelete = async (id) => {
        if (window.confirm('Are you sure?')) {
            var getProducts = await apiRequest({}, URL.BASE_URL + '/LocationCategories/'+id, 'delete');
            setTimeout(() => {
                window.location.reload();
            }, 300);
        } else {
            return false;
        }
    }
    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };
    const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
    }
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
    }
    const handleLoadMore = () => {
        setitemsPerPage(itemsPerPage + 5);
    };
    const searchCategories = (value) => {
        let categories = get_data;
        const searchString = value.toLowerCase();
        if (searchString.length > 0) {
            const filteredResults = categories.filter(item => {
                return (
                    item.name.toLowerCase().includes(searchString)
                )
            });
            if (filteredResults) {
                setCategories(filteredResults);
                setLoading(false);
                setcurrentPage(1);
            } else {
                fetchCategories();
            }
        } else {
            fetchCategories();
        }
    }
    return (
        <div>
            <html lang="en">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <div class="sticky">
                    <Header />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="manage_staff">
                                    <div class="sec_title">
                                        <h2 class="head_sty1">{localStorage.getItem("restaurant_name")} | Categories</h2>
                                        <div class="search_box_wrap search_customer">
                                            <div style={{ "display": "flex" }}>
                                                <div style={{ "display": "flex" }}>
                                                    <input type="text" onChange={(e) => searchCategories(e.target.value)} id="searchbar" id="search" class="form-control" placeholder="Type to search..." />
                                                    <i style={{ "background": highlightColor ? highlightColor : "#fff" }} class="fa fa-search fa-border custom_icon"></i>
                                                </div>
                                                <button class="btn_sty_1" onClick={handleShow} >Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    {(loading == false) ? renderData(currentItems) : <img className="loading" src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"></img>}
                                    <Modal class="modalform" align="center" size="s" style={{ 'width': '1000px;', 'padding-top': '150px' }} class="modal-dialog modal-dialog-centered modal-lg" fullscreen='true' show={show} onHide={handleClose}>
                                        <div class="modal-content">
                                            <Modal.Header closeButton>
                                                <Modal.Title>{labels}</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div class="modal-body">
                                                    <section class="staff_profile_sec category_form add_cate_form">
                                                        <div class="staff_profile_pic_box">
                                                            <div class="staff_profile_img">
                                                                {
                                                                    (images == '') ?
                                                                        <img src='../img-placeholder.png' alt="" /> : <img src={images} alt="" />}
                                                            </div>
                                                            <label class="custom-file-upload-category">
                                                                <input type="file" value={categories_image} onChange={e => _onChangeImage(e.target.files[0])} />
                                                                Upload Image
                                                            </label>
                                                            {
                                                                (viewImageError) ?
                                                                    <small></small> : <><small style={{ color: 'red' }}>{view_image_error}</small></>
                                                            }
                                                        </div>
                                                        <div class="staff_profile_form">
                                                            <form action="">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="form-group">
                                                                            <label>Category  Name</label>
                                                                            <input type="text" class="form-control" value={name} onChange={e => _onChangeText('category_name', e.target.value)} />
                                                                            {
                                                                                (viewNameError) ?
                                                                                    <small></small> : <><small style={{ color: 'red' }}>{view_name_error}</small></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </section>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer >
                                                <div class="col-md-12">
                                                    <div class="form-group mb-0 staff_profile_btn_group">
                                                        <button type="submit" class="btn_sty_1" style={{ "min-width": '110px' }} onClick={() => onSubmit()}>Submit</button>
                                                    </div>
                                                </div>
                                            </Modal.Footer>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
            <ul className="pageNumbers">
                <li onClick={handlePrevbtn}
                    className={currentPage == pages[0] ? "disallow" : "allow"}>
                    {"<"}
                </li>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                <li onClick={handleNextbtn}
                    className={currentPage == pages[pages.length - 1] ? "disallow" : "allow"}>
                    {">"}
                </li>
            </ul>
        </div>
    )
}
export default ResCategories;