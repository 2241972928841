import Taxcode from "../pagination/taxcodes";

function TaxCodesList(props) {
    return (
        props.values.data.length > 0 ?
            <div>
                {
                    props.values.data.length > 0
                        ? props.values.data?.map((item, index) => {
                            return (
                                <div  key={item.id} class="m_staff_box taxations_box">
                                    <div class="m_staff_top">
                                        <div class="m_staff_info">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <h2 class="r_name">{item?.name}</h2>
                                                </div>
                                                <div class="col-md-6 r_align_right view_id_btn_wrap">
                                                    <label class="switch">
                                                        <input type="checkbox" disabled={false}
                                                            checked={props.values?.get_data[index]?.enable} value={item?.enable} onChange={e => props?.values.handleCheckbox(item, index)} />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div>
                                                </div>
                                                <div class="col-md-6">
                                                    <h4><span>Tax Type:</span>  {(item?.type == "Product Based") ? "Category Based" : "Invoice Based"}</h4>
                                                </div>
                                                <div class="col-md-6 r_align_right">
                                                    <h4><span>Tax Percentage:</span> {item?.percent}</h4>
                                                </div>
                                            </div>
                                            <div class="m_staff_bottom">
                                                <h4><span>Locations:</span> {(item?.location.length > 0) ? item?.location?.map(animal => (<ul><li>{animal?.name}</li></ul>)) : ""}</h4>
                                                <div class="btn_group">
                                                    <a href="#" class="grey_btn" data-toggle="modal" data-target="#edit_taxationsModal" onClick={e => props?.values.onEdit(item)}>Edit</a>
                                                    <button class="grey_btn" onClick={e => props?.values.onDelete(item?.id)}>Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        : <div align="center" style={{ 'font-size': '16' }} >{props?.values?.view_show_msg}</div>
                }
            </div> : <center><b>No Records found!!</b></center>
    );
}
export default TaxCodesList;