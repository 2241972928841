import { AuthHeader, HandleResponse, constants } from "../_helpers";
import * as URL from '../../../services/ServiceConstant';
const API_ENDPOINT = URL.BACKEND_ROOT;
export const ProjectSettingsService = {
  create,
  getAll,
  update,
  updateSMTPSettings,
  updateSMSSettings,
  updatePaymentSettings,
  updateLoginScreenSettings,
  sendTestEmail,
  getSMTPSettings,
  getPaymentSettings,
  getSMSSettings,
  getAPIKeys,
  getGoogleAPIKeySettings,
  updateGoogleAPIKeySettings,
  createApiKey,
  updateApiKey,
  deleteAPIKey,
  locale: {
    getCountries,
    setCountry,
    setTimezone,
    setCurrency,
    setDateFormat,
    setTimeFormat,
    setCountryCode
  }
};

function updateSMTPSettings(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
  return fetch(`${API_ENDPOINT}api/projectSettings/smtp`, requestOptions).then(HandleResponse);
}

function updatePaymentSettings(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
  return fetch(`${API_ENDPOINT}api/projectSettings/payment`, requestOptions).then(HandleResponse);
}

function updateGoogleAPIKeySettings(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
  return fetch(`${API_ENDPOINT}api/projectSettings/google`, requestOptions).then(HandleResponse);
}

function updateSMSSettings(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
  return fetch(`${API_ENDPOINT}api/projectSettings/sms`, requestOptions).then(HandleResponse);
}

function updateLoginScreenSettings(image) {
  const form_data = new FormData();
  form_data.append("login_image", image);
  const requestOptions = {
    method: "POST",
    headers: {
      ...AuthHeader()
    },
    body: form_data
  };
  return fetch(`${API_ENDPOINT}api/lba_theme`, requestOptions).then(HandleResponse);
}

function sendTestEmail() {
  const requestOptions = {
    method: "POST",
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({})
  };
  return fetch(`${API_ENDPOINT}api/projectSettings/smtp/testEmail`, requestOptions).then(HandleResponse);
}

function create(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...AuthHeader(),
    },
    body: body
  };
  return fetch(`${API_ENDPOINT}api/projectSettings`, requestOptions).then(HandleResponse);
}

function update(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...AuthHeader(),
    },
    body: body
  };
  return fetch(`${API_ENDPOINT}api/projectSettings`, requestOptions).then(HandleResponse);
}

function getAll() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${API_ENDPOINT}api/projectSettings`, requestOptions).then(HandleResponse);
}

export function getSMTPSettings() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${API_ENDPOINT}api/projectSettings/smtp`, requestOptions).then(HandleResponse);
}

export function getPaymentSettings() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${API_ENDPOINT}api/projectSettings`, requestOptions).then(HandleResponse);
}

export function getGoogleAPIKeySettings() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${API_ENDPOINT}api/projectSettings`, requestOptions).then(HandleResponse);
}

export function getSMSSettings() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${API_ENDPOINT}api/projectSettings`, requestOptions).then(HandleResponse);
}

export function getAPIKeys() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${API_ENDPOINT}api/projectSettings/api_key`, requestOptions).then(HandleResponse);
}

export function deleteAPIKey(id) {
  const requestOptions = { method: "DELETE", headers: AuthHeader() };
  return fetch(`${API_ENDPOINT}api/projectSettings/api_key/${id}`, requestOptions).then(HandleResponse);
}

export function getCountries() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(`${API_ENDPOINT}api/projectSettings/countries`, requestOptions).then(HandleResponse);
}

export function setCountry(body) {
  const requestOptions = {
    method: "PATCH",
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
  return fetch(`${API_ENDPOINT}api/projectSettings/countries`, requestOptions).then(HandleResponse);
}

export function setTimezone(body) {
  const requestOptions = {
    method: "PATCH",
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
  return fetch(`${API_ENDPOINT}api/projectSettings/timezone`, requestOptions).then(HandleResponse);
}

export function setCurrency(body) {
  const requestOptions = {
    method: "PATCH",
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
  return fetch(`${API_ENDPOINT}api/projectSettings/currency`, requestOptions).then(HandleResponse);
}

export function setCountryCode(body) {
  const requestOptions = {
    method: "PATCH",
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
  return fetch(`${API_ENDPOINT}api/projectSettings/countryCode`, requestOptions).then(HandleResponse);
}

export function setDateFormat(body) {
  const requestOptions = {
    method: "PATCH",
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
  return fetch(`${API_ENDPOINT}api/projectSettings/dateFormat`, requestOptions).then(HandleResponse);
}

export function setTimeFormat(body) {
  const requestOptions = {
    method: "PATCH",
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
  return fetch(`${API_ENDPOINT}api/projectSettings/timeFormat`, requestOptions).then(HandleResponse);
}

export function createApiKey() {
  const requestOptions = {
    method: "POST",
    headers: {
      ...AuthHeader(),
    },
    body: {}
  };
  return fetch(`${API_ENDPOINT}api/projectSettings/api_key`, requestOptions).then(HandleResponse);
}

export function updateApiKey(id, key, value) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ id, [key]: value })
  };
  return fetch(`${API_ENDPOINT}api/projectSettings/api_key`, requestOptions).then(HandleResponse);
}
