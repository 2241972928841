import HOME from '../components/home.js';
import ALERTS from '../components/alerts.js';
import EmailTemplates from '../components/emailtemplates.js';
import SETTINGS from "../components/settings.js";
import CREATEORDERS from "../components/pagination/orders.js";
import CREATEADDON from "../components/createaddons.js";
import CREATEPRODUCT from "../components/createproducts.js";
import CREATE_RESTAURANT_PRODUCT from "../components/create_restaurant_product.js";
import PRODUCTS from "../components/pagination/products.js";
import TAXCODES from "../components/pagination/taxcodes.js";
import FAQS from "../components/faqs.js";
import TERMSANDCONDITIONS from "../components/termsandconditions";
import ABOUTUS from "../components/aboutus.js";
import PROMOCODES from "../components/pagination/promocodes.js";
import BANNERS from "../components/pagination/banners.js";
import PROFILE from "../components/myProfile.js";
import CATEGORIES from "../components/pagination/categories.js";
import RESTAURANT_MANAGER_CATEGORIES from "../components/pagination/restaurant_manager_categories.js";
import RESTAURANT_CATEGORIES from "../components/pagination/restaurant_categories.js";
import RESTAURANT_PRODUCTS from "../components/pagination/restaurant_products.js";
import RESTAURANT_MANAGER_PRODUCTS from "../components/pagination/restaurant_manager_products.js";
import LOCATION from "../components/pagination/location.js";
import LOCATION_MANAGER from "../components/pagination/locationmanager";
import STAFF from "../components/pagination/staff";
import CUSTOMER from "../components/pagination/customer.js";
import FORGOT_PASSWORD from "../components/forgotpassword.js";
import Transactions from "../components/pagination/transactions.js";
import ProductReviews from '../components/pagination/productreviews.js';
import Messages from '../components/pagination/messages.js';
import SuperAdmin from '../components/superAdmin.js';
var indexRoutes = [
    { path: "/home", name: "home", component: HOME },
    { path: "/alerts", name: "alerts", component: ALERTS },
    { path: '/forgotpassword', name: "forgotpassword", component: FORGOT_PASSWORD },
    { path: '/customers', name: "customers", component: CUSTOMER },
    { path: '/drivers', name: "drivers", component: STAFF },
    { path: '/locationmanager', name: "locationmanager", component: LOCATION_MANAGER },
    { path: '/location', name: "location", component: LOCATION },
    { path: '/category', name: "category", component: CATEGORIES },
    { path: '/restaurant-manager-categories', name: "category", component: RESTAURANT_MANAGER_CATEGORIES },
    { path: '/restaurant-categories/:locationId', name: "restaurant categories", component: RESTAURANT_CATEGORIES },
    { path: '/restaurant-products/:locationId', name: "restaurant products", component: RESTAURANT_PRODUCTS },
    { path: '/restaurant-manager-products', name: "restaurant products", component: RESTAURANT_MANAGER_PRODUCTS },
    { path: '/home', name: "home", component: HOME },
    { path: '/profile', name: "profile", component: PROFILE },
    { path: '/banners', name: "banners", component: BANNERS },
    { path: '/promocode', name: "promocode", component: PROMOCODES },
    { path: '/aboutus', name: "aboutus", component: ABOUTUS },
    { path: '/termsandconditions', name: "termsandconditions", component: TERMSANDCONDITIONS },
    { path: '/faqs', name: "faqs", component: FAQS },
    { path: '/taxcodes', name: "taxcodes", component: TAXCODES },
    { path: '/product', name: "product", component: PRODUCTS },
    { path: '/createproduct', name: "createproduct", component: CREATEPRODUCT },
    { path: '/create-restaurant-product/:restaurant_id', name: "createproduct", component: CREATE_RESTAURANT_PRODUCT },
    { path: '/createaddon', name: "createaddon", component: CREATEADDON },
    { path: '/orders', name: "orders", component: CREATEORDERS },
    { path: '/settings', name: "settings", component: SETTINGS },
    { path: '/transactions', name: "transactions", component: Transactions },
    { path : '/email-templates', name:"emailtemplates", component : EmailTemplates},
    { path: '/product-reviews', name: "productreviews", component: ProductReviews },
    { path: '/messages', name: "messages", component: Messages },
    { path: '/superadmin', name: "superadmin", component: SuperAdmin },

];
export default indexRoutes;
