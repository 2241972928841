import React, { useState, useEffect, useRef } from 'react';
import bell from '../assets/img/bell.svg'
import * as Url from '../../src/services/ServiceConstant';
import apiRequest from "../services/ServiceRequest";
import { ToastContainer, toast } from 'react-toastify';
import { fetchNotification } from '../notifications/index';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
const Header = (props) => {
    const [locations, setLocationData] = useState("");
    const [locationId, setLocationId] = useState(0);
    // const [notification_indicator, setNotificationsIndicator] = useState(false);
    const locationss = useLocation();
    const [logo, setLogo] = useState('');
    const [topHeaderBg, settopHeaderBg] = useState('');
    const [topHeaderFont, settopHeaderFont] = useState('white');
    const [parameter, setParamater] = useState("");
    const [notifications, setNotifications] = useState([]);
    const [notificationsIndicator, setNotificationsIndicator] = useState(false);
    const prevNotificationsCount = useRef(0);
    const ref = useRef(null)
    useEffect(() => {
        var url = window.location.href;
        var result = url.split('/');
        var Param = result[result.length - 2];
        // alert(Param);
        setParamater(Param);
        if (localStorage.getItem("role") == "businessowner") {
            ref.current = setInterval(async () => {
                var item = await fetchNotification();
                toast.success(item?.message, { toastId: item?.id, });
            }, 10000);
            return () => {
                if (ref.current) {
                    //   clearInterval(ref.current);
                }
            }
        }
        if (localStorage.getItem("role") == "locationmanager") {
            ref.current = setInterval(async () => {
                var item = await fetchNotification();
                toast.success(item?.message, { toastId: item?.id, });
            }, 10000);
            return () => {
                if (ref.current) {
                    //   clearInterval(ref.current);
                }
            }
        }
    }, [])

    const fetchNotifications = async () => {
        const prevCount = parseInt(localStorage.getItem('prevNotificationsCount')) || 0;
        const apiResponse = await apiRequest([], `${Url.BASE_URL}/Notifications?filter={"order":"id DESC","where":{"type":"web","status":"closed"}}`, 'get');
        const newNotifications = apiResponse.data;
        if (newNotifications.length > prevCount) {
            setNotificationsIndicator(true);
        }
    
        setNotifications(newNotifications);
        localStorage.setItem('prevNotificationsCount', newNotifications.length.toString());
    };
    
    // Hook to fetch notifications on component mount and set interval
    const useFetchNotifications = () => {
        const [notifications, setNotifications] = useState([]);
        const [notificationsIndicator, setNotificationsIndicator] = useState(false);
    
        useEffect(() => {
            const fetchAndStoreNotifications = async () => {
                await fetchNotifications();
            };
    
            fetchAndStoreNotifications();
    
            const intervalId = setInterval(fetchAndStoreNotifications, 60000); // Fetch notifications every minute
    
            return () => clearInterval(intervalId);
        }, []);
    
        return { notifications, notificationsIndicator };
    };
// useEffect to load notifications initially and set up polling
useEffect(() => {
    fetchNotifications();

    const intervalId = setInterval(() => {
        fetchNotifications();
    }, 60000); // Polling every 60 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
}, []);

// Handle bell icon click
const handleBellClick = () => {
    setNotificationsIndicator(false);
};
    useEffect(async () => {
        if (window.location.protocol.indexOf('https') == 0) {
            var el = document.createElement('meta')
            el.setAttribute('http-equiv', 'Content-Security-Policy')
            el.setAttribute('content', 'upgrade-insecure-requests')
            document.head.append(el)
        }
        if (locationss.pathname == "/locationmanager" || locationss.pathname == "/drivers" || locationss.pathname == "/product" || locationss.pathname == "/orders" || locationss.pathname == "/promocode" || locationss.pathname == "/taxcodes" || locationss.pathname == "/banners" || locationss.pathname == "/home") {
            var get_locations = await apiRequest([], Url.LOCATION + '?filter={"where":{"id_deleted":' + false + '}}', 'get')
        }
        if (get_locations == null || get_locations == undefined || get_locations.data === '{}' || Object.entries(get_locations.data).length === 0 || get_locations == null || get_locations == undefined || get_locations.length <= 0 || get_locations.data.length <= 0) {
        }
        else {
            let temp_Array = [];
            if (localStorage.getItem("role") == "businessowner") {
                temp_Array = get_locations.data.models;
            }
            else {
                var temp_locations = localStorage.getItem("locations").split(',');
                var locations_to_be_set = [];
                for (var i = 0; i < get_locations.data.models.length; i++) {
                    for (var j = 0; j < temp_locations.length; j++) {
                        if (temp_locations[j] == get_locations.data.models[i].id) {
                            locations_to_be_set.push({
                                id: get_locations.data.models[i].id,
                                name: get_locations.data.models[i].name
                            });
                        }
                    }
                }
                temp_Array = locations_to_be_set;
            }
            temp_Array.unshift({ id: 0, name: "All Locations", value: "all" })
            setLocationData(temp_Array)
            if (locationss.pathname != '/category') {
                props.parentCallback(temp_Array[0].id);
            }
        }
        if (window.location.href.substring(window.location.href.lastIndexOf("/") + 1) == "location") {
            document.getElementById('locationactive').classList.add('active');
        }
        if (window.location.href.substring(window.location.href.lastIndexOf("/") + 1) == "drivers") {
            document.getElementById('driveractive').classList.add('active');
        }
        if (window.location.href.substring(window.location.href.lastIndexOf("/") + 1) == "orders") {
            document.getElementById('ordersactive').classList.add('active');
        }
        if (window.location.href.substring(window.location.href.lastIndexOf("/") + 1) == "home") {
            document.getElementById('homeactive').classList.add('active');
        }
        let themeSettings = await apiRequest([], Url.BASE_URL + '/lba_theme', 'get');
        let result = themeSettings?.data;
        settopHeaderBg(result?.topHeaderBg);
        settopHeaderFont(result?.topHeaderFont);
        var projectSettings = await apiRequest([], Url.BASE_URL + '/projectSettings/', 'get');
        if (projectSettings.data.extracted[0].logo != "N/A") {
            setLogo(projectSettings.data.extracted[0].logo);
        }
    }, []);
    const _onChangeText = (type, text) => {
        if (type === 'assigned_location') {
            if (text !== '') {
                document.getElementById(text).text = document.getElementById(text).innerHTML;
                document.getElementById("locs").style.fontWeight = '600';
                document.getElementById("locs").style.fontSize = '16px';
                setLocationId(text)
                props.parentCallback(text);
            }
        }
    }
    const showHamburgerMenu = () => {
        var condition = document.getElementById("menu_icon").innerHTML;
        if (condition == '<i class="icofont-navigation-menu"></i>') {
            document.getElementById("leftSidebarNavigation").style.display = 'block';
            document.getElementById("menu_icon").innerHTML = '<b>X</b>';
            var mainContent = document.getElementsByClassName('main_content');
            for (var i = 0; i < mainContent.length; i++) {
                var screenResolution = window.screen.availHeight + window.screen.availWidth;
                var width = window.screen.availWidth;
                // if (width == 768) {
                //     mainContent[i].style.margin = "584px 0px 0px 0px";
                // }
                // if (screenResolution == 1960) {
                //     mainContent[i].style.margin = "-584px 0px 0px 0px";
                // }
                // else if (screenResolution == 1421) {
                //     mainContent[i].style.margin = "-591px 0px 0px 0px";
                // }
                // else if (screenResolution == 1024) {
                //     mainContent[i].style.margin = "-591px 0px 0px 0px";
                // }
                // else {
                //     mainContent[i].style.margin = "-561px 0px 0px 0px";
                // }
            }
        }
        else {
            document.getElementById("leftSidebarNavigation").style.display = 'none';
            document.getElementById("menu_icon").innerHTML = '<i class="icofont-navigation-menu"></i>';
            var mainContent = document.getElementsByClassName('main_content');
            for (var i = 0; i < mainContent.length; i++) {
                var screenResolution = window.screen.availHeight + window.screen.availWidth;
                if (screenResolution == 1960) {
                    mainContent[i].style.margin = "0px 0px 0px 0px";
                }
                else if (screenResolution == 1421) {
                    mainContent[i].style.margin = "0px 0px 0px 0px";
                }
                else {
                    mainContent[i].style.margin = "0px 0px 0px 0px";
                }
            }
        }
    }
    return (
        <div style={{ 'position': 'sticky' }} >
            <head>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <link rel="shortcut icon" type="image/png" href="img/logo.png" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap" rel="stylesheet" />
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous" />
                <link rel="stylesheet" href="css/icofont.css" />
                <link rel="stylesheet" href="css/jquery.mCustomScrollbar.css" />
                <link rel="stylesheet" href="css/custom.css" />
                <title>Food Delivery</title>
            </head>
            <ToastContainer />
            {
                topHeaderBg ? <header class="header_inside" >
                    <div class="sticky">
                        <nav style={{ "background": topHeaderBg }} class='navbar navbar-dark top_nav'>
                            <div class="container-fluid">
                                <div class="header_wrap">

                                    <div>

                                        {
                                            logo ? <img style={{ "width": "150px", "height": "60px" }} src={logo}></img> : ''
                                        }
                                    </div>








                                    <div onClick={showHamburgerMenu} id="menu_icon" class="menu_icon">
                                        <i class="icofont-navigation-menu"></i>
                                    </div>
                                    {
                                        (locationss.pathname == '/location' || parameter == "restaurant-categories" || parameter == "restaurant-products" || locationss.pathname == '/category' || locationss.pathname == '/messages' || locationss.pathname == '/profile' || locationss.pathname == '/aboutus' || locationss.pathname == '/termsandconditions' || locationss.pathname == '/transactions' || locationss.pathname == '/customers' || locationss.pathname == '/settings' || locationss.pathname == '/faqs' || locationss.pathname == '/email-templates' || locationss.pathname == '/createproduct' || locationss.pathname == '/createaddon' || locationss.pathname == '/alerts' || locationss.pathname == '/superadmin') ?
                                            null :
                                            <div style={{ "margin-top:": "17px !important" }} class="head_selector_wrap">
                                                <p style={{ "color": topHeaderFont, "display": "inline-flex", "font-size": "20px", "font-weight": "500" }}>{
                                                    localStorage.getItem("multi_restaurant") == 'true' ? 'Select Restaurant' : 'Select Location'
                                                } :</p>
                                                <select
                                                    style={{ "color": topHeaderFont }}
                                                    id="locs"
                                                    value={locationId}
                                                    placeholder="Select Location"
                                                    onChange={e => _onChangeText('assigned_location', e.target.value)}
                                                >
                                                    {locations.length > 0

                                                        ? locations?.map((item, index) => {
                                                            return (
                                                                <option id={item.id} value={item.id}  key={item.id}>{item.name}</option>

                                                            )
                                                        })
                                                        : null
                                                    }
                                                </select>
                                            </div>
                                    }
                                   
                                    <div className="notification_wrap">
                                        <Link to="/alerts" className="notification" onClick={handleBellClick}>
                                            <img src={bell} alt="Notification Bell" />
                                            {notificationsIndicator && <div style={{ display: 'block' }} className="notification_indicator"></div>}
                                        </Link>
                                    </div>
                                    {/* Render notifications or other UI components here */}
                                </div>
                                    {/* Render notifications or other UI components here */}
                              
                            </div>
                        </nav>
                    </div>
                </header> : ''
            }

        </div>
    )
}
export default Header;