import { AuthHeader, HandleResponse, constants } from "../_helpers";
import { BehaviorSubject } from "rxjs";

const modelsSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("models"))
);

export const ModelService = {
  getAll,
  listAll,
  create,
  remove,
  currentModels: modelsSubject.asObservable(),
  get allModels() {
    return modelsSubject.value;
  }
};

function getAll() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(constants.GET_MODELS, requestOptions)
    .then(HandleResponse)
    .then((res) => {
      modelsSubject.next(res.models);
      return res;
    });
}

function remove(id) {
  const requestOptions = { method: "DELETE", headers: AuthHeader() };
  return fetch(constants.REMOVE_MODELS(id), requestOptions)
    .then(HandleResponse)
    .then((res) => {
      modelsSubject.next(res.models);
      return res;
    });
}

function listAll() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(constants.LIST_MODELS, requestOptions).then(HandleResponse);
}

function create(body) {
  const requestOptions = { 
    method: "POST", 
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    }, 
    body: JSON.stringify(body)
  };
  return fetch(constants.ADD_MODEL, requestOptions).then(HandleResponse);
}

// Dynamically import ProjectSettingsService only when needed
export async function getProjectSettings() {
  const { ProjectSettingsService } = await import("../_services/ProjectSettings.service");
  return ProjectSettingsService.getSettings();
}
