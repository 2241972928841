import { BehaviorSubject } from "rxjs";
import { AuthHeader, HandleResponse, constants } from "../_helpers";

const safeParseJSON = (item) => {
  try {
    return JSON.parse(item);
  } catch (error) {
    return null;
  }
};

const storedUser = localStorage.getItem("currentUser");
const currentUserSubject = new BehaviorSubject(safeParseJSON(storedUser));

export const AuthenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  addRole,
  getRoles,
  getPermissions,
  updatePermissions,
  updateDynamicPassword,
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`/api/users/login?${new URLSearchParams({ include: 'user' })}`, requestOptions)
    .then(HandleResponse)
    .then((user) => {
      localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);
      return user;
    });
}

function logout() {
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}

function getRoles() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch(constants.ROLES.GET_ALL, requestOptions).then(HandleResponse);
}

function updateDynamicPassword(newPassword, model, access_token) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ newPassword }),
  };

  return fetch(`/api/${model}/reset-password?${new URLSearchParams({ access_token })}`, requestOptions)
    .then(HandleResponse);
}

function addRole(name) {
  const requestOptions = { 
    method: "POST", 
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    }, 
    body: JSON.stringify({ name })
  };

  return fetch(constants.ROLES.ADD_NEW, requestOptions).then(HandleResponse);
}

function getPermissions(roleId) {
  const requestOptions = { 
    method: "GET", 
    headers: AuthHeader()
  };

  return fetch(constants.PERMISSIONS.GET_ALL(roleId), requestOptions).then(HandleResponse);
}

function updatePermissions(body) {
  const requestOptions = { 
    method: "POST", 
    headers: {
      ...AuthHeader(),
      "Content-Type": "application/json"
    }, 
    body: JSON.stringify(body) 
  };

  return fetch(constants.PERMISSIONS.UPDATE, requestOptions).then(HandleResponse);
}
