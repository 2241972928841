import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./css/orders_filters.css";
const OrderFilters = (props) => {

    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const dropdownRef = useRef(null);

    // Load initial state from localStorage
    useEffect(() => {
        const savedStatuses = JSON.parse(localStorage.getItem('selectedStatuses')) || [];
        setSelectedStatuses(savedStatuses);
    }, [props.values]);

    // Handle changes in checkboxes
    const handleChange = (event) => {
        const value = event.target.value;
        const newSelectedStatuses = selectedStatuses.includes(value)
            ? selectedStatuses.filter(status => status !== value)
            : [...selectedStatuses, value];

        setSelectedStatuses(newSelectedStatuses);
        localStorage.setItem('selectedStatuses', JSON.stringify(newSelectedStatuses));
        props.values.filterByStatus(newSelectedStatuses);
    };

    const toggleDropdown = () => {
        document.getElementById("myDropdown").classList.toggle("show");
        document.getElementById("filter-d").classList.toggle("opne_show");
    };
    window.onclick = function(event) {
        if (!event.target.matches('.dropbtn')) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            for (var i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
        }
    };
    const options = [
        // { value: 'all', label: 'All Orders' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Accepted', label: 'Accepted' },
        { value: 'Cancelled', label: 'Cancelled' },
        { value: 'Preparing', label: 'Preparing' },
        { value: 'Prepared', label: 'Prepared' },
        { value: 'Picked Up', label: 'Picked Up' },
        { value: 'Delivered', label: 'Delivered' },
    ];

    return (
        <div class="order_top_block">
            <div class="row">
                <div class="col-md-3">
                    <div class="order_search">
                        <input type="text" value={props.values.serachedname} onChange={props.values.filter} class="form-control" placeholder="Search by Name or ID..." />
                        <a href="#"><i class="icofont-search-1"></i></a>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="s_date_wrap form-control" style={{
                        alignSelf: 'center', width: '100%',
                        display: 'flex',
                        justifyContent: 'spaceBetween',
                        alignItems: 'center'
                    }} >
                        <DatePicker
                            className='order-date-picker-css'
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            placeholder="Select Date Range"
                            selectsRange={true}
                            maxDate={new Date()}
                            isClearable
                            startDate={props.values.startDate}
                            endDate={props.values.endDate}
                            onChange={(update) => {
                                if (update[0]==null && update[1]==null) {
                                    console.log("one");
                                    window.location.reload();
                                }
                                props.values.setDateRange(update);
                                var strToDate = new Date(props.values.dateRange[0]);
                                if (update.length == 2 && update[0] != null && update[1] != null) {
                                    console.log("two");
                                    props.values.filterDateRange(update)
                                    return
                                }
                            }}
                        />
                    {!props.values.endDate > 0 && (
    <i 
        className="icofont-ui-calendar calendar_icon" 
        style={{ paddingLeft: 10, margin: "-4px -130px 7px 5px" }} 
        htmlFor='fromdate'
    ></i>
)}
                    </div>
                </div>
                <div className="col-md-3">
            <div className="order_sort">
                <div className="dropdown filter-d" id='filter-d'>
                    <button onClick={toggleDropdown} className="dropbtn">
                        Filter by status
                    </button>
                    <div id="myDropdown" className="dropdown-content myDropdownClass">
                        {options.map(option => (
                            <label key={option.value}> 
                            <div className='dropbox_fix'>  <input
                                    type="checkbox"
                                    value={option.value}
                                    checked={selectedStatuses.includes(option.value)}
                                    onChange={handleChange}
                                />
                              <span className='dropbox_span_fix'>{option.label}</span> </div>
                              
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </div>
                <div class="col-md-2">
                    <button style={{ "padding": "8px 20px" }} onClick={() => props.values.setManualOrderClose(true)} class="btn_sty_1">Add</button>
                </div>
            </div>
        </div>
    );
}
export default OrderFilters;