import React, { useState, useEffect } from "react";
import {
  Label,
  Card,
  CardTitle,
  CardBody,
  Form,
  Row,
  Col,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { WebNotification, ProjectSettingsService } from '../components/jwt/_services';

export default function SMSSettings(props) {
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onBlur"
  });
  toast.configure()
  const [smsMethod, setSMSMethod] = useState('');
  const [twillioApiKey, setTwillioApiKey] = useState('');
  const [twillioApiSecret, setTwillioApiSecret] = useState('');
  const [twillioAccountSID, setTwillioAccountSID] = useState('');
  const [twillioDefaultFrom, setTwillioDefaultFrom] = useState('');
  const [twillioDefaultFromError, setTwillioDefaultFromError] = useState('');
  const [twillioAccountSIDError, setTwillioAccountSIDError] = useState('');
  const [twillioApiKeyError, setTwillioApiKeyError] = useState('');
  const [twillioApiSecretError, setTwillioApiSecretError] = useState('');


  useEffect(() => {
    loadSMSSettings();
  }, []);

  const loadSMSSettings = () => {
    ProjectSettingsService.getSMSSettings().then(data => {
      if (!data.extracted.length) return;
      const settings = data.extracted[0];
      if (settings.id && settings.smsSetting) {
        setSMSMethod(settings.smsSetting.type);
        setTwillioAccountSID(settings.smsSetting.twillio_account_sid);
        setTwillioApiKey(settings.smsSetting.twillio_api_key);
        setTwillioApiSecret(settings.smsSetting.twillio_api_secret);
        setTwillioDefaultFrom(settings.smsSetting.twillio_default_from);
      }
    });
  };

  const onSubmit = () => {
    let hasError = false;
  
    // Clear previous errors
    setTwillioApiSecretError("");
    setTwillioApiKeyError("");
    setTwillioAccountSIDError("");
    setTwillioDefaultFromError("");
  
    // Validate fields
    if (!twillioApiSecret) {
      setTwillioApiSecretError("This field is required.");
      hasError = true;
    }
  
    if (!twillioApiKey) {
      setTwillioApiKeyError("This field is required.");
      hasError = true;
    }
  
    if (!twillioAccountSID) {
      setTwillioAccountSIDError("This field is required.");
      hasError = true;
    }
  
    if (!twillioDefaultFrom) {
      setTwillioDefaultFromError("This field is required.");
      hasError = true;
    }
  
    // If there are errors, stop form submission
    if (hasError) {
      return;
    }
  
    // Proceed with form submission if no errors
    const smsSetting = {
      type: smsMethod,
      twillio_account_sid: twillioAccountSID,
      twillio_api_key: twillioApiKey,
      twillio_api_secret: twillioApiSecret,
      twillio_default_from: twillioDefaultFrom
    };
  
    ProjectSettingsService.updateSMSSettings({ smsSetting }).then(() => {
      toast.success("Updated Successfully");
      loadSMSSettings();
    }).catch(() => {
      toast.error("Failed to update settings.");
    });
  };
  

  return (
    <Card>
      <CardBody className="bg-light">
        <CardTitle><i className="mdi mdi-currency-usd mr-2"></i>
        <h2 class="heading_settings mb-3">SMS Settings</h2>
      </CardTitle>
      </CardBody>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="12">
              <Label>{'SMS Gateway: ' + smsMethod}</Label>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Twillio Default From Number</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name='twillio_default_from'
                    id='twillio_default_from'
                    placeholder="From Contact number"
                    value={twillioDefaultFrom}
                    onChange={(e) => setTwillioDefaultFrom(e.target.value)}
                    onBlur={handleSubmit(onSubmit)}
                    invalid={!!twillioDefaultFromError}
                  />
                </InputGroup>
                {twillioDefaultFromError && <FormFeedback>{twillioDefaultFromError}</FormFeedback>}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Twillio SID</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name='twillio_account_sid'
                    id='twillio_account_sid'
                    placeholder="Twillio Account SID"
                    value={twillioAccountSID}
                    onChange={(e) => setTwillioAccountSID(e.target.value)}
                    onBlur={handleSubmit(onSubmit)}
                    invalid={!!twillioAccountSIDError}
                  />
                </InputGroup>
                {twillioAccountSIDError && <FormFeedback>{twillioAccountSIDError}</FormFeedback>}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Twillio API KEY</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name='twillio_api_key'
                    id='twillio_api_key'
                    placeholder="Twillio API Key"
                    value={twillioApiKey}
                    onChange={(e) => setTwillioApiKey(e.target.value)}
                    onBlur={handleSubmit(onSubmit)}
                    invalid={!!twillioApiKeyError}
                    />
                  </InputGroup>
                  {twillioApiKeyError && <FormFeedback>{twillioApiKeyError}</FormFeedback>}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Twillio API Secret</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name='twillio_api_secret'
                    id='twillio_api_secret'
                    placeholder="Twillio API Secret"
                    value={twillioApiSecret}
                    onChange={(e) => setTwillioApiSecret(e.target.value)}
                    onBlur={handleSubmit(onSubmit)}
                    invalid={!!twillioApiSecretError}
                    />
                  </InputGroup>
                  {twillioApiSecretError && <FormFeedback>{twillioApiSecretError}</FormFeedback>}
              </FormGroup>
            </Col>
          </Row>
          {/* <Row>
            <Col md="12">
              <Button type="submit" color="primary">Save</Button>
            </Col>
          </Row> */}
        </Form>
      </CardBody>
    </Card>
  );
}
