import React, { useState, useEffect } from "react";
import { 
  Card,
  CardTitle,
  CardBody,
  Form,
  Row,
  Col,
  Input,
  FormGroup,
  Button,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { FaCopy, FaTrash } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { WebNotification, ProjectSettingsService } from '../components/jwt/_services';
import "./css/superAdmin.css";

export default function APIKeySettings(props) {
  const [updating, setUpdating] = useState('');
  const [keys, setKeys] = useState([]);
  const [editingName, setEditingName] = useState({}); // State to manage the editing input values

  useEffect(() => {
    loadAPIKeys();
  }, []);

  const loadAPIKeys = () => {
    ProjectSettingsService.getAPIKeys().then(setKeys);
  }

  const addNewKey = () => {
    ProjectSettingsService.createApiKey({}).then(() => {
      loadAPIKeys();
    });
  }

  const updateAPIKey = (id, key, value) => {
    ProjectSettingsService.updateApiKey(id, key, value).then((data) => {
      WebNotification.success(data.message, "", 1);
      loadAPIKeys();
    });
  }

  const deleteAPIKey = (id) => {
    ProjectSettingsService.deleteAPIKey(id).then((data) => {
      WebNotification.success("Removed Successfully", "", 1);
      loadAPIKeys();
    }).catch(() => {
      WebNotification.success("Removed Failed with error", "", 1);
    });
  }

  const handleToggleChange = (apiKeyId, checked) => {
    const newStatus = checked ? 'enabled' : 'disabled';
    updateAPIKey(apiKeyId, "status", newStatus);
  }

  const handleNameChange = (id, value) => {
    setEditingName((prev) => ({ ...prev, [id]: value }));
  }

  return (
    <Card>
      <CardBody className="bg-light">
        <CardTitle><i className="mdi mdi-currency-usd mr-2"></i>
        <h2 class="heading_settings mb-3">API KEY Settings</h2>
        </CardTitle>
      </CardBody>
      <CardBody className="spadmin-api-box">
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <span>
              <a href="#!" className="text-success pb-10" onClick={addNewKey}>
                Create new API key
              </a>
            </span>
          </Col>
        </Row>
        <Form>
          {keys.map((api_key, i) => (
            <Row key={i} inline="true">
              <Col md="2" style={{ paddingRight: '0' }}>
                <Label
                  style={{ padding: '7px', fontSize: '13px', cursor: 'pointer' }}
                  className={updating !== api_key.id ? 'show' : 'hide'}
                  onClick={() => {
                    setUpdating(api_key.id);
                    setEditingName((prev) => ({ ...prev, [api_key.id]: api_key.name }));
                  }}
                >
                  {api_key.name} <MdModeEdit />
                </Label>
                <InputGroup>
                  <Input
                    className={"form-control " + (updating === api_key.id ? 'show' : 'hide')} 
                    style={{ borderRadius: 0 }}
                    autoFocus
                    type="text"
                    placeholder="name"
                    value={editingName[api_key.id] || ''}
                    onChange={(e) => handleNameChange(api_key.id, e.target.value)}
                    onBlur={(e) => {
                      setUpdating('');
                      updateAPIKey(api_key.id, "name", e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col md="6" style={{ paddingLeft: '0' }}>
                <FormGroup>
                  <InputGroup>
                    {/* <Input
                      defaultValue={api_key.key}
                      readOnly
                      disabled
                    /> */}
                     <InputGroupText>{api_key.key}</InputGroupText>
                    <InputGroupAddon addonType="append">
                      <Button onClick={() => {
                        navigator.clipboard.writeText(api_key.key);
                        WebNotification.success("API KEY Copied in Clipboard", "", 1);
                      }} size="sm"> <FaCopy /></Button>
                      <InputGroupText style={{ height: "35px" }}>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={api_key.status === 'enabled'}
                            onChange={(e) => handleToggleChange(api_key.id, e.target.checked)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </InputGroupText>
                      <Button onClick={() => deleteAPIKey(api_key.id)} color="danger" size="sm"> <FaTrash /> Remove</Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          ))}
        </Form>
      </CardBody>
    </Card>
  )
}
