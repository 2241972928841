import { useState, useEffect } from "react";
import Header from "./header"
import Sidebar from "./menu"
import * as Url from '../../src/services/ServiceConstant';
import { Modal } from 'react-bootstrap';
import apiRequest from "../services/ServiceRequest";
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { fetchNotification } from '../notifications/index';
import 'react-toastify/dist/ReactToastify.css';
import ProductReviews from "./productreviews";
import * as EmailTemplates from '../utils/emailtemplates.js';
import { setLocalizationSettings, filterLocations, LoaderGif, sortProducts, dynamicStylesheet } from "../utils/common.js"
const Products = (props) => {
    let history = useHistory();
    toast.configure()
    const [fileError, setfileError] = useState("");
    const [global_currency, setGlobalCurrency] = useState('');
    const [product_id, setProductID] = useState("");
    const [proId, setPrimaryProductId] = useState("");
    const [index, setIndex] = useState("");
    const [images, setImage] = useState("");
    const [highlightColor, setHighLightColor] = useState('');
    const [name, setName] = useState("");
    const [serachedname, serachedName] = useState('');
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState([]);
    const [addons, setAddons] = useState([]);
    const [price, setPrice] = useState("");
    const [ID, setProductId] = useState("");
    const [view_show_msg, show_mesage] = useState("");
    const [get_data, setProductData] = useState("");
    const [products, setProducts] = useState("");
    const [filtered_products, setFilteredProducts] = useState([]);
    const [show, setShow] = useState(false);
    const [popupshow, setShowPopup] = useState(false);
    const [productlocations, setProductLocations] = useState("");
    const [enabless, setEnables] = useState(false);
    const [message, setMessage] = useState("");
    const [submit, setSubmit] = useState(false);
    const [labels, labelText] = useState("");
    const [categories, setCategories] = useState([]);
    const [edits, setEdit] = useState(false);
    const [enable_status, setProductEnableStatus] = useState("");
    const [profile_image, setprofile_image] = useState("");
    const [view_error, viewError] = useState("");
    const [csvfile, setcsvfile] = useState("");
    const [csv, setcsv] = useState("");
    const [productreviews, setProductReviews] = useState(false);
    const [product_id_for_review, setProductReviewsId] = useState('');
    const [loading, setLoading] = useState(true);
    const handleCallback = async (childData) => {
        let endpoints = ''
        if (childData == 0) {
            endpoints = Url.BASE_URL+'/admin-products'
            var get_staff = await apiRequest([], endpoints, 'post')
            if (get_staff == null || get_staff == undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
                setProductData("")
                setLoading(false);
            }
            else {
                if (localStorage.getItem("role") == "businessowner") {
                    var products = await sortProducts(get_staff.data);
                    setProductData(products)
                    setFilteredProducts(products);
                    setcurrentPage(1);
                    setLoading(false);
                }
                else {
                    var locationsassigned = localStorage.getItem("locations").split(',');
                    var locationManagerProducts = [];
                    for (var i = 0; i < locationsassigned.length; i++) {
                        for (var j = 0; j < get_staff.data.length; j++) {
                            for (var k = 0; k < get_staff.data[j].location.length; k++) {
                                if (get_staff.data[j].location[k].id == locationsassigned[i]) {
                                    locationManagerProducts.push(get_staff.data[j]);
                                }
                            }
                        }
                    }
                    var productsDataLocs = locationManagerProducts.filter((li, idx, self) => self.map(itm => itm.id).indexOf(li.id) === idx)
                    var products = await sortProducts(productsDataLocs);
                    setProductData(products)
                    setFilteredProducts(products);
                    setcurrentPage(1);
                    setLoading(false);
                }
            }
        }
        else if (childData != 0) {
            var productsData = [];
            endpoints = Url.BASE_URL+'/admin-products'
            var get_staff = await apiRequest([], endpoints, 'post')
            if (get_staff == null || get_staff == undefined || get_staff.data.length <= 0) {
                setProductData(get_staff.data)
                setFilteredProducts(get_staff.data);
                setLoading(false);
            }
            else {
                for (var i = 0; i < get_staff.data.length; i++) {
                    for (var j = 0; j < get_staff.data[i].location.length; j++) {
                        if (get_staff.data[i].location[j].id == childData) {
                            productsData.push(get_staff.data[i]);
                        }
                    }
                }
                if (localStorage.getItem("role") == "businessowner") {
                    setProductData(productsData)
                    setFilteredProducts(productsData);
                }
                else {
                    var locationsassigned = localStorage.getItem("locations").split(',');
                    var locationManagerProducts = [];
                    for (var i = 0; i < locationsassigned.length; i++) {
                        for (var j = 0; j < productsData.length; j++) {
                            for (var k = 0; k < productsData[j].location.length; k++) {
                                if (productsData[j].location[k].id == locationsassigned[i]) {
                                    locationManagerProducts.push(productsData[j]);
                                }
                            }
                        }
                    }
                    var productsDataLocs = locationManagerProducts.filter((li, idx, self) => self.map(itm => itm.id).indexOf(li.id) === idx)
                    var products = await sortProducts(productsDataLocs);
                    setProductData(products)
                    setFilteredProducts(products);
                    setLoading(false);
                }
            }
        }
    }
    const renderData = (data) => {
        return (
            (data.length>0) ? 
            <div class="row">
                {
                    data.length > 0
                        ? data?.map((item, index) => {
                            return (
                                <div class="col-sm-6 col-md-4">
                                    <div class="product_box">
                                        <a href="#" class="product_details" data-toggle="modal" data-target="#product_detailModal"></a>
                                        <div class="product_img" key={item.id} >
                                            <img onClick={() => handleShow(item, index)} src={item.image} style={{ height: "300px", width: "700px" }} alt="" />
                                        </div>
                                        <div class="product_info">
                                            <div style={{"display" : "flex"}}>
                                                <h3>{item?.name} ({item?.categories?.name})</h3>
                                            </div>
                                            {
                                                [1, 2, 3, 4, 5].map((row, index) => {
                                                    return (<span style={{"cursor":"pointer"}}  onClick={() => {
                                                        setProductReviewsId(item.id);
                                                        setProductReviews(true);
                                                    }} className={row > item.aggregatereview ? "fa fa-star star_rating_unchecked" : "fa fa-star star_rating_checked"}></span>);
                                                })

                                            }
                                            {item.totalreviews != null ? " (" + item.totalreviews + ")" : " (0)"}
                                            {
                                                item?.enable == true ? <button style={{ "float": "right" }} className="btn btn-success">Active</button> : <button style={{ "float": "right" }} className="btn btn-danger">Inactive</button>
                                            }
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                            )
                        })
                        : <div align="center" style={{ 'font-size': '16', 'paddingLeft': '800px' }} >{view_show_msg}</div>
                }
            </div> : <center><b>No records found!!</b></center>
        );
    };
    const getproductdata = async () => {
        let endpoint = Url.BASE_URL+'/admin-products'
        var get_staff = await apiRequest([], endpoint, 'post')
        var categoryData = await apiRequest([], Url.BASE_URL + '/Categories?filter={"where":{"id_deleted":' + false + ',"enable":' + true +'}}', 'get');
        setCategories(categoryData.data);
        if (get_staff == null || get_staff == undefined || get_staff.data === '{}' || Object.entries(get_staff.data).length === 0 || get_staff === null || get_staff === undefined || get_staff.length <= 0 || get_staff.data.length <= 0) {
            setLoading(false);
        }
        else {
            if (localStorage.getItem("role") == "businessowner") {
                var products = await sortProducts(get_staff.data);
                setProducts(products);
                setFilteredProducts(products);
                setLoading(false);
            }
        }
    }
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(9);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
    };
    const pages = [];
    for (let i = 1; i <= Math.ceil(filtered_products.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filtered_products.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={handleClick}
                    className={currentPage == number ? "active" : null}
                >
                    {number}
                </li>
            );
        } else {
            return null;
        }
    });
    useEffect(async () => {
        var parts = window.location.href.split('/');
        var lastSegment = parts.pop() || parts.pop();

        if (lastSegment == '') {
            history.push('/product');
        } else {
            setcurrentPage(lastSegment);
            history.push('/product');
        }

        let dynamicCSS = await dynamicStylesheet();
        setHighLightColor(dynamicCSS?.highlightColor);
        const queryParameters = new URLSearchParams(window.location.search)
        var productId = queryParameters?.get("id");
        var endpoints = Url.BASE_URL + '/admin-products'
        var get_staff = await apiRequest([], endpoints, 'post');
        if (productId != null) {
            var products = get_staff.data;
            var product = products.filter(function (item) {
                return item.id == productId;
            });
            handleShow(product[0],0);
        }
        var localSettings = await setLocalizationSettings();
        await setGlobalCurrency(localSettings?.[1]);
        await apiRequest([], Url.BASE_URL + '/delete-empty-addons', 'get');
        getproductdata();
    }, []);
    const filter = (e) => {
        const keyword = e;
        if (keyword.trim() == "") {
            return false;
        }
        var searchedProducts = filtered_products.filter(function (item) {
            if (item.productId == keyword) {
                return item.productId == keyword;
            } else if (item.name == keyword) {
                return item.name == keyword;
            }
        });
        setProductData(searchedProducts);
        setFilteredProducts(searchedProducts);
    }
    const handleCheckbox = async (ID, enable_status) => {
        var product = {
            id : ID
        }
        if (enable_status == true) {
            product.enable = false;
        } else {
            product.enable = true;
        }
        await apiRequest(product, Url.BASE_URL + '/Products', 'patch');
        setProductEnableStatus(product.enable);
        getproductdata();
    }
    const notify = (message) => {
        toast.success(message)
    }
    const errornotify = (message) => {
        toast.error(message)
    }
    setInterval(async () => {
        var item = await fetchNotification();
        toast.success(item?.message, { toastId: item?.id, });
    }, 30000);
    const handleShow = async (item, i) => {
        setProductID(item?.productId)
        setProductEnableStatus(item?.enable);
        setPrimaryProductId(item?.id);
        setName(item?.name)
        setImage(item?.image)
        var active_locations = item?.location?.filter(function (item) {
            return item?.id_deleted == false && item?.enable==true;
        });
        setProductLocations(active_locations);
        setDescription(item?.description)
        if (item?.categories != null && item?.categories != undefined) {
            setCategory(item?.categories?.name)
        }
        fetchAddOns(item?.id);
        if (item?.pricings?.length > 0) {
            setPrice(item?.pricings[0]?.unit_price)
        }
        setEnables(item?.enable)
        setProductId(item?.id)
        setIndex(i)
        setShow(true)
    };
    const fetchAddOns = async (productId) => {
        var addons = await apiRequest({
            product: productId
        }, Url.BASE_URL + '/addon-categories', 'post');
        var addonsData = [];
        for (var i = 0; i < addons.data.models.length; i++) {
            for (var j = 0; j < addons.data.models[i].addOns.length; j++) {

            }
            if (productId == addons.data.models[i].product) {
                addonsData.push(addons.data.models[i]);
            }
        }
        setAddons(addonsData);
    }
    const handleClose = () => {
        setShow(false)
    };
    const handleCloseProductReview = () => {
        setProductReviews(false)
    };
    const handleClosePopup = () => {
        setShowPopup(false)
    };
    const onDelete = async (id ,name) => {
        if (window.confirm('Are you sure?')) {
            let delete_product = {
                "id": id,
                id_deleted: true
            }
            var delete_locationmanager = await apiRequest(delete_product, Url.PRODUCTS, 'patch')
            if (delete_locationmanager == null || delete_locationmanager == undefined || delete_locationmanager.data.length <= 0) {
                errornotify("'Failed'");
            }
            else {
                notify("Deleted successfully");
                if (localStorage.getItem("role") == "locationmanager") {
                    await apiRequest({
                        title: "Location manager has deleted the product",
                        message: "Location manager has deleted the product",
                        type: "web",
                        role : "businessowner",
                        status: "open",
                        datetime: new Date().toISOString()
                    }, Url.BASE_URL + '/Notifications', 'post');
                    await EmailTemplates.productDeleted_LocationManager({
                        name: name,
                        username: localStorage.getItem("username"),
                    });
                }
                setShow(false)
                let temp = [...filtered_products]
                temp.splice(index, 1)
                setMessage("hello")
                setProductData(temp)
                setFilteredProducts(temp);
                setMessage("hello")
                getproductdata()
                // handleCallback(0);
                setcurrentPage(currentPage);
            }   
        } else {
            return false;
        }
    }
    const handleShowPopup = () => {
        setSubmit(false)
        viewError("")
        setprofile_image("");
        setShowPopup(true)
        labelText("Add Driver")
        setEdit(false)
    };
    const _onChangeImage = async (images) => {
        if (images.type != "text/csv") {
            setfileError("Please upload the following file types: CSV.");
            setcsvfile("");
            return false;
        }
        else {
            setcsv(images);
            setcsvfile(images.name);
        }
    };
    const uploadCSV = async () => {
        if (csvfile == '') {
            setfileError("Please upload the file.");
            return false;
        }
        const formData = new FormData();
        formData.append('csv', csv);
        let apiBody = formData;
        await apiRequest(apiBody, Url.BASE_URL + '/uploadProductCsv', 'post')
        setcsvfile('');
        notify("successfully uploaded!!");
        setTimeout(function () {
            window.location.href = '/product';
        }, 2000);
    };
    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };
    const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
    }
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
    }
    const handleLoadMore = () => {
        setitemsPerPage(itemsPerPage + 5);
    };
    const SearchData = (value) => {
        value = value.replace('#', '');
        if (value == '') {
            handleCallback(0);
        }
        let data = filtered_products;
        const searchString = value.toString().toLowerCase();
        if (searchString.length > 0) {
            const filteredResults = data.filter(item => {
                return (
                    item.productId.toString().toLowerCase().includes(searchString) || item.name.toString().toLowerCase().includes(searchString) || item?.categories?.name.toString().toLowerCase().includes(searchString)
                )
            });
            if (filteredResults) {
                setProductData(filteredResults);
                setFilteredProducts(filteredResults);
            } else {
                getproductdata();
            }
        } else {
            getproductdata();
        }
    }
    const deleteAddOn = async (id) => {
        let apiBody = {
            "id": id,
            id_deleted: true
        };
        await apiRequest(apiBody, Url.ADDONS, 'patch')
        await fetchAddOns(proId);
        notify("Deleted successfully")
    }
    const deleteCategory = async (id) => {
        if (window.confirm('are you sure you want to delete this category?')) {
            var apiBody = {
                id: id,
                id_deleted: true
            };
            await apiRequest(apiBody, Url.ADDONCATEGORY, 'patch');
            await fetchAddOns(proId);
            notify("Deleted successfully")
        }
    };
    const searchByCategory = async (categoryId) => {
        if (categoryId == "") {
            window.location.reload();
        } else {
            var categoryProds = [];
            for (var i = 0; i < get_data.length; i++){
                if (get_data[i].categoryId == categoryId) {
                    categoryProds.push(get_data[i]);
                }
            }
            setFilteredProducts(categoryProds);
            setcurrentPage(1);
        }
    }

    function generateCSV() {
        const csvHeader = 'Name,ProductId,Description,Price,Category,Locations,AddOnCategory,AddOnName,AddOnCost,AddOnCategory,AddOnName,AddOnCost,AddOnCategory,AddOnName,AddOnCost\n';
        const blob = new Blob([csvHeader], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Products.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
      
    return (
        <div>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            <ToastContainer />
            <html lang="en">
                <div class="sticky">
                    <Header parentCallback={(params) => handleCallback(params)} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="product_page">
                                    <h2 class="head_sty1">Products</h2>
                                    <div class="product_top_block">
                                        <div style={{"width" : "65%"}}>
                                            <div style={{ "display": "flex" }}>
                                                <input type="text" onChange={(e) => SearchData(e.target.value)} id="searchbar" id="search" class="form-control" placeholder="Type to search..." />
                                                <i style={{ "background": highlightColor ? highlightColor : "#fff" }} class="fa fa-search fa-border custom_icon"></i>
                                                <select onChange={(e) => searchByCategory(e.target.value)} style={ {"margin-left" : "10px"}} className="form-control">
                                                    <option value="">Select Category</option>
                                                    {
                                                        categories.map((item, index) => {
                                                            return (
                                                                <option value={item.id}>{item.name}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <button style={{"text-transform":"none"}} class="btn_sty_1 add_new" onClick={e => history.push('/createproduct')}>Add New</button>
                                        <button style={{ "text-transform": "none" }} class="btn_sty_1 add_new" onClick={handleShowPopup}>Import CSV</button>
                                    </div>
                                    <div class="product_block">
                                        {(loading==false) ? renderData(currentItems) : <img className="loading" src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"></img>}
                                    </div>
                                </div>
                            </div>
                            <Modal class="modalform" size="lg" class="modal-dialog modal-dialog-centered modal-lg" style={{ 'width': '1000px;', 'padding-top': '150px' }} fullscreen='true' show={show} onHide={handleClose}>
                                <div class="modal-content">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Product Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div class="modal-body">
                                            <div class="product_detail_top">
                                                <div class="pro_del_img"  >
                                                    <img src={images} alt="" />
                                                </div>
                                                <div class="pro_del_info">
                                                    <div class="pro_det_top_info">
                                                        <h3>{name}</h3>
                                                        <label class="switch">
                                                            <input type="checkbox" disabled={false}
                                                                checked={enable_status} value={enable_status} onChange={e => handleCheckbox(ID, enable_status)} />
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                    <p>{description} </p>
                                                </div>
                                            </div>
                                            <div class="pro_del_stats">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="pro_stats_box">
                                                            <h3>Product ID: </h3>
                                                            <p>#{product_id}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="pro_stats_box">
                                                            <h3>Category: </h3>
                                                            <p>{category}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="pro_stats_box">
                                                            <h3>Price: </h3>
                                                            <p>{price}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="pro_stats_box">
                                                            <h3 style={{ "margin-bottom": "-22px" }}>Locations: </h3>
                                                        </div>
                                                        {
                                                            productlocations.length > 0
                                                                ? productlocations.map(function (item) {
                                                                    return item.name;
                                                                }).join(', ')
                                                                : ''
                                                        }
                                                        <br></br><br></br>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <Link to={{ pathname: 'createproduct', state: ID, pageNumber : currentPage ? currentPage : 1 }} class="btn_sty_1 edit" >Edit</Link>
                                                        < button class="btn_sty_2" onClick={e => onDelete(ID, name)} >Remove</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pro_add_on">
                                                <div class="pro_addon_title">
                                                    <h3>Add Ons</h3>
                                                    <Link to={{ pathname: 'createaddon', state: ID }} class="btn_sty_1" onClick={e => history.push('/createaddon')} >Manage </Link>
                                                </div>
                                                <div class="pro_addon_block">
                                                    {
                                                        addons.length > 0
                                                            ? addons?.map((item1, index1) => {
                                                                return (
                                                                    <div>
                                                                        <h5 style={{ "margin": "0px 0px 11px 0px", "display": "contents" }}>{(item1.addOns.length > 0) ? item1?.name : ''}</h5> {item1.addOns.length > 0 ? <a href="#" onClick={() => deleteCategory(item1.id)}><i style={{ "margin-left": "15px", "background": "#e48e8e" }} class="icofont-close removeBtnBox"></i></a> : ''}
                                                                        <div class="row">
                                                                            {
                                                                                item1.addOns.length > 0
                                                                                    ? item1.addOns?.map((item2, index2) => {
                                                                                        return (
                                                                                            <div style={{ "margin-bottom": "12px" }} class="col-md-6">
                                                                                                <div id={item2.id} style={{ "margin-top": "15px" }} class="pro_addon_box">
                                                                                                    <a href="#" onClick={() => deleteAddOn(item2.id)} class="remove_addon"><i class="icofont-close removeBtnBox"></i></a>
                                                                                                    <div class="addon_pic"><img style={{ "height": "90px" }} src={(item2.images == "" || item2.images == undefined) ? './img-placeholder.png' : Url.image_url + item2.images} alt="" /></div>
                                                                                                    <div class="addon_info">
                                                                                                        <h3>{item2.name}</h3>
                                                                                                        <div class="addon_botom">
                                                                                                            <span>{global_currency+ item2.price}</span>
                                                                                                            <span>{item2.calories}Cal</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </div>
                            </Modal>
                            <Modal class="modalform" size="lg" class="modal-dialog modal-dialog-centered modal-lg" style={{ 'width': '1000px;', 'padding-top': '150px' }} fullscreen='true' show={popupshow} onHide={handleClosePopup}>
                                <div class="modal-content">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Upload Products CSV</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div class="modal-body">
                                            <section class="staff_profile_sec">
                                                <div class="staff_profile_form">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>CSV File</label>
                                                                <div class="custom-file">
                                                                    <input type="file" onChange={e => _onChangeImage(e.target.files[0])} class="custom-file-input" id="customFile" />
                                                                    <label style={{ "width": "450px" }} class="custom-file-label" for="customFile">{(csvfile) ? csvfile : 'Choose File'}</label>
                                                                </div>
                                                                <p style={{ "color": "red", "width":"500px" }}>{fileError}</p>
                                                            </div>
                                                            <button style={{ "background-color": "#47a061" }} onClick={generateCSV} class="btn btn-success">Download Sample Template</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer >
                                        <div class="col-md-12">
                                            <div class="form-group mb-0 staff_profile_btn_group">
                                                <button onClick={uploadCSV} type="submit" class="btn_sty_1" style={{ align: 'left' }}> Submit</button>
                                            </div>
                                        </div>
                                    </Modal.Footer>
                                </div>
                            </Modal>
                            <Modal class="modalform" size="lg" class="modal-dialog modal-dialog-centered modal-lg" style={{ 'width': '1000px;', 'padding-top': '150px' }} fullscreen='true' show={productreviews} onHide={handleCloseProductReview}>
                                <div class="modal-content">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Product Reviews</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <ProductReviews productId={product_id_for_review} />
                                    </Modal.Body>
                                    <Modal.Footer >

                                    </Modal.Footer>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </body >
            </html >
            {filtered_products.length>0 ?
                <ul className="pageNumbers">
                <li onClick={handlePrevbtn}
                    className={currentPage == pages[0] ? "disallow" : "allow"}>
                    {"<"}
                </li>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                <li onClick={handleNextbtn}
                    className={currentPage == pages[pages.length - 1] ? "disallow" : "allow"}>
                    {">"}
                </li>
            </ul> : ''}
        </div >
    )
}
export default Products;
