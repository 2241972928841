import React, { useState, useEffect } from "react";
import { 
  Alert,
  Card,
  CardTitle,
  CardBody,
  Form,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  FormFeedback 
} from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { WebNotification, ProjectSettingsService } from '../components/jwt/_services';

export default function SMTPSettings(props) {
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur"
  });
  toast.configure()
  const [host, setHost] = useState('');
  const [port, setPort] = useState('587');
  const [tls, setTLS] = useState('true');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [testEmailError, setTestEmailError] = useState('');
  const [emailInProgress, setEmailInProgress] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [hostError, setHostError] = useState(''); // Add loading state
  const [portError, setPortError] = useState(''); // Add loading state
  const [usernameError, setUsernameError] = useState(''); // Add loading state
  const [passwordError, setPasswordError] = useState(''); // Add loading state

  const loadSMTPSettings = async () => {
    try {
      const settings = await ProjectSettingsService.getSMTPSettings();
      if (settings.id) {
        setHost(settings.host);
        setPort(settings.port);
        setTLS(settings.tls);
        setUsername(settings.username);
        setPassword(settings.password);
      }
    } catch (error) {
      console.error("Error loading SMTP settings:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    loadSMTPSettings();
  }, []);

  const onSubmit = async (data) => {
    // Validation errors storage
    const errors = {
      host: '',
      port: '',
      username: '',
      password: '',
    };
  
    // Helper function to validate fields
    const validateField = (fieldName, value) => {
      if (!value.trim()) {
        errors[fieldName] = 'This field is required.';
      }
    };
  
    // Validate each field
    validateField('host', host);
    validateField('port', port);
    validateField('username', username);
    validateField('password', password);
  
    // Update state with validation errors
    setHostError(errors.host);
    setPortError(errors.port);
    setUsernameError(errors.username);
    setPasswordError(errors.password);
  
    // If there are any errors, stop form submission
    if (Object.values(errors).some(error => error)) {
      return;
    }
  
    // Construct SMTP settings object
    const smtpSettings = { host, port, username, password };
  
    try {
      await ProjectSettingsService.updateSMTPSettings(smtpSettings);
      toast.success('Email details updated successfully.');
      loadSMTPSettings();
    } catch (error) {
      console.error('Error updating SMTP settings:', error);
    }
  };
  

  const sendTestEmail = async () => {
    setEmailInProgress(true);
    try {
      const result = await ProjectSettingsService.sendTestEmail();
      if(result.success){
          toast.success('Send email details successful.')
      }else{
        toast.error('Please enter correct credentials.')
      }
    //   WebNotification.success(`Send Email ${result.success ? 'Successful' : 'Failed'}`, "", 1);
      if (!result.success) {
        setTestEmailError(result.message);
        setTimeout(() => {
          setTestEmailError('');
        }, 5000);
      }
    } catch (error) {
      console.error("Error sending test email:", error);
    } finally {
      setEmailInProgress(false);
    }
  };

  if (loading) {
    return <Spinner color="primary" />; // Show spinner while loading
  }

  return (
    <Card>
      <CardBody className="bg-light">
        <CardTitle><i className="mdi mdi-email-secure mr-2"></i>
        
        <h2 class="heading_settings mb-3">SMTP Settings</h2></CardTitle>
      </CardBody>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="4">
              <FormGroup>
                <div className="d-flex">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>HOST</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name='host'
                    id='host'
                    placeholder="smtp.example.com"
                    value={host}
                    onChange={(e) => setHost(e.target.value)}
                    onBlur={handleSubmit(onSubmit)}
                    invalid={!!hostError}
                    />
                    </div>
                    {hostError && <FormFeedback>{hostError}</FormFeedback>}
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                
                <InputGroup>
                <div className="d-flex">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>PORT</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    name='port'
                    id='port'
                    placeholder="587"
                    value={port}
                    onChange={(e) => setPort(e.target.value)}
                    onBlur={handleSubmit(onSubmit)}
                    invalid={!!portError}
                    />
                    </div>
                    {portError && <FormFeedback>{portError}</FormFeedback>}
                </InputGroup>
                {/* {errors.port && <p className="errorMsg">{errors.port.message}</p>} */}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormGroup>
                {/* <InputGroup> */}
                <div className="d-flex">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Username</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name='username'
                    id='username'
                    placeholder="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onBlur={handleSubmit(onSubmit)}
                    invalid={!!usernameError}
                    />
                    </div>
                    {usernameError && <FormFeedback>{usernameError}</FormFeedback>}
                {/* </InputGroup> */}
                {/* {errors.username && <p className="errorMsg">{errors.username.message}</p>} */}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <InputGroup>
                <div className="d-flex">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Password</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="password"
                    name='password'
                    id='password'
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={handleSubmit(onSubmit)}
                    invalid={!!passwordError}
                    />
                    </div>
                    {passwordError && <FormFeedback>{passwordError}</FormFeedback>}
                </InputGroup>
                {/* {errors.password && <p className="errorMsg">{errors.password.message}</p>} */}
              </FormGroup>
            </Col>
          </Row>
          <Button
            disabled={emailInProgress}
            className="btn btn-success"
            onClick={sendTestEmail}
          >
            {emailInProgress ? <Spinner size="sm" /> : <i className="mdi mdi-email-variant"></i>} 
            {emailInProgress ? ' Sending...' : ' SEND TEST EMAIL'}
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
}
