export const constants = {
    GET_MODELS:'/api/lba_models/all',
    REMOVE_MODELS:id=>`/api/lba_models/${id}`,
    LIST_MODELS:'/api/v1/lba_models/all.json',
    ADD_MODEL:'/api/v1/models/addnew',
    UPDATE_USER:id=>`/api/v1/users/${id}`,
    UPDATE_MODEL:id=>`/api/v1/models/${id}`,
    ROLES:{
        GET_ALL:'/api/v1/roles',
        ADD_NEW:'/api/v1/roles'
    },
    PERMISSIONS:{
        GET_ALL:(roleId)=>`/api/lba_permissions/all/${roleId}`,
        UPDATE:'/api/lba_permissions'
    },
    PROPERTY:{
        ADDNEW:(modelId)=>`/api/v1/lba_property/addNew/${modelId}`,
        UPDATE:(id)=>`/api/v1/lba_property/${id}`,
        REMOVE:(id)=>`/api/v1/lba_property/${id}`
    },
    DYNAMIC_MODELS:{
        ADD_NEW:name=>`/api/v1/dynamic_models/${name}/addnew`,
        GET_ALL:(id,name)=>`/api/v1/dynamic_models/${name}/${id}/all`,
        REMOVE:(name,id)=>`/api/v1/dynamic_models/${name}/${id}`,
        UPDATE:(name,id)=>`/api/v1/dynamic_models/${name}/${id}`,
        CHANGE_PASSWORD:(name,id)=>`/api/v1/dynamic_models/${name}/${id}/updatepassword`,
        RELATIONS:{
            GET:(name,id,relation)=>`/api/v1/dynamic_models/${name}/${id}/relations/${relation}`
        },
    },
    SETTINGS:{
        LOCALIZATION:{
            COUNTRY:{
                GET:'/api/projectSettings/countries',
                UPDATE:'/api/projectSettings/countries'
            },
            TIMEZONE:{
                UPDATE:'/api/projectSettings/timezone'
            },
            CURRENCY:{
                UPDATE:'/api/projectSettings/currency'
            },
            DATE_FORMAT:{
                UPDATE:'/api/projectSettings/dateFormat'
            },
            TIME_FORMAT:{
                UPDATE:'/api/projectSettings/timeFormat'
            },
            COUNTRYCODE: {
                UPDATE: '/api/projectSettings/countryCode',
                POST: '/api/projectSettings/countryCode'
            }
        },
        SMTP:{
            GET:'/api/projectSettings/smtp',
            UPDATE:'/api/projectSettings/smtp',
            TEST_EMAIL:'/api/projectSettings/smtp/testEmail'
        },
        COUNTRYCODE: {
            GET: '/api/projectSettings/countryCode',
            UPDATE: '/api/projectSettings/countryCode'
        },
        PAYMENT:{
            GET:'/api/projectSettings',
            POST:'/api/projectSettings/payment',
        },
        GOOGLE:{
            GET:'/api/projectSettings',
            POST:'/api/projectSettings/google',
        },
        SMS:{
            GET:'/api/projectSettings',
            POST:'/api/projectSettings/sms',
        },
        THEME:{
            GET:'/api/lba_theme',
            UPDATE:'/api/lba_theme'
        },
        API_KEY:{
            GET:'/api/projectSettings/api_key',
            POST:'/api/projectSettings/api_key',
            DELETE:(id)=>`/api/projectSettings/api_key/${id}`
        }
    }
}