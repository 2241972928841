import { constants,AuthHeader, HandleResponse } from "../_helpers";

export const ThemeService = {
  update,
  getAll
};

function update(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...AuthHeader(),
      'Content-Type':'application/json'
    },
    body: JSON.stringify(body)
  };
  return fetch("https://delapi.ng.jumpgrowth.com/api/lba_theme", requestOptions).then(HandleResponse);
}


function getAll() {
  const requestOptions = { method: "GET", headers: AuthHeader() };
  return fetch("https://delapi.ng.jumpgrowth.com/api/lba_theme", requestOptions).then(HandleResponse);
}
