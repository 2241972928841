import Moment from 'react-moment'; // Make sure to import Moment if it's used for date formatting

function CustomersList(props) {
    return (
        <>
            {
                props.values.data.length > 0
                    ? props.values.data.map((item, index) => {
                        return (
                            <div className="m_staff_box" key={item.id || index}>
                                <div className="m_staff_top">
                                    <div className="img_box">
                                        {item.profile_image ? <img src={item.profile_image} alt="" /> : <img src={'./img-placeholder.png'} alt="" />}
                                    </div>
                                    <div className="m_staff_info">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h2 className="r_name">{item.firstname ? item.firstname : item.first_name}</h2>
                                            </div>
                                            <div className="col-md-6">
                                                <h4><span>Phone:</span> {item.mobile}</h4>
                                            </div>
                                            <div className="col-md-6 r_align_right">
                                                <h4><span>Email:</span> {item.email}</h4>
                                            </div>
                                        </div>
                                        <div className="m_staff_bottom">
                                            <h4>
                                                <span>Last Ordered:</span>
                                                {item?.last_ordered !== undefined ? (
                                                    <Moment format={props.values.global_date || " DD MMM  YYYY-h:mm A"}>
                                                        {item?.last_ordered || "30 Dec 2020 12:30 PM"}
                                                    </Moment>
                                                ) : ""}
                                            </h4>
                                            {item.block_status === "unblocked" ? (
                                                <div className="btn_group">
                                                    <button type="button" onClick={() => props.values.getCustomerOrders(item.id)} className="btn_sty_1">View Orders</button>
                                                    <a style={{ width: "105px" }} href="#" onClick={() => props.values.blockCustomer(item.id, 'blocked')} className="grey_btn">Block</a>
                                                </div>
                                            ) : (
                                                <div className="btn_group">
                                                    <button type="button" onClick={() => props.values.getCustomerOrders(item.id)} className="btn_sty_1">View Orders</button>
                                                    <a href="#" onClick={() => props.values.blockCustomer(item.id, 'unblocked')} className="grey_btn">Unblock</a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    : <div align="center" style={{ fontSize: '16px' }}>{props.values.view_show_msg}</div>
            }
        </>
    );
}

export default CustomersList;
