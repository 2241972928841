import React, { useEffect, useState } from "react";
import apiRequest from "../services/ServiceRequest";
import * as Url from "../../src/services/ServiceConstant";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { Modal, Dropdown } from "react-bootstrap";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "./header";
import category1 from "../assets/img/customer1.jpg";
import Sidebar from "./menu";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
  useLoadScript,
} from "@react-google-maps/api";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
import { setLocalizationSettings, getSalesGraphObj } from "../utils/common.js";
import CanvasJSReact from "../helpers/canvasjs.react";
import Statistics from "./home/statistics";
import OrdersList from "./orders_list";
import OrdersETA from "./orders_eta";
import OrdersAvailableDrivers from "./orders_available_drivers";
import OrdersEdit from "./orders_edit";
import moment from "moment";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const Home = (props) => {
  toast.configure();
  let endpoints = "";
  let orderamount = 0;
  let recent_orders = [];
  let recent_customers = [];
  const [markers, setMarkers] = useState([]);
  const [dropoff_image, setDropOffImage] = useState("");
  const [InfoWindowStatus, setInfoWindowStatus] = useState("");
  const [status_value, setStatusvalue] = useState("");
  const [view_show_msg, show_mesage] = useState("");
  const [offer_amount, setOfferAmount] = useState("");
  const [orders, setOrders] = useState("");
  const [pending_count, setPendingOrders] = useState("");
  const [accpected_count, setAccpectedOrders] = useState("");
  const [completed_count, setCompletedOrders] = useState("");
  const [order_total, setOrderTotal] = useState("");
  const [customer_count, setCustomerCount] = useState("");
  const [locations_count, setLocationsCount] = useState("");
  const [eta, setEta] = useState();
  const [driver_side_status, setDriverSideStatus] = useState("");
  const [mapRef, setMapRef] = useState(null);
  const [show, setShow] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [order_detail, setOrderDetail] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [item_total, setItemTotal] = useState("");
  const [address_name, setAddressName] = useState("");
  const [tax_total, setTaxTotal] = useState("");
  const [type, setType] = useState("");
  const [sheduled_date, sheduledDate] = useState("");
  const [ordered_date, orderedDate] = useState("");
  const [orderno, setOrderNo] = useState("");
  const [instructions, setInstructions] = useState("");
  const [note, setNote] = useState("");
  const [statusus, setStatusus] = useState("");
  const [products, setProducts] = useState("");
  const [driver_name, setDriverName] = useState("");
  const [weekly, setWeeklyGraph] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [get_orders_data, setOrdersData] = useState("");
  const [tipData, setTip] = useState("");
  const [graph_data, setGraphData] = useState("");
  const [edit_order, setEdit] = useState(false);
  const [order_detail_id, setOrderDetailId] = useState("");
  const [assign_driver, setAssgin] = useState(false);
  const [drivers_data, setDrivers] = useState("");
  const [ordered_drivers_data, setOrderedDrivers] = useState("");
  const [driver_id, setDriverId] = useState("");
  const [order_data, setOrderList] = useState([]);
  const [Options, setOptions] = useState();
  const [product_name, setProductName] = useState("");
  const [product_image, setProductImage] = useState("");
  const [product_price, setProductPrice] = useState("");
  const [delivery_amount, setDeliveryAmount] = useState("");
  const [product_quantity, setProductQuantity] = useState(0);
  const [centerPoint, setCenterPoint] = useState(0);
  const [driverInfo, setdriverInfo] = useState([]);
  const [graph_total_data, setGraphTotalData] = useState([]);
  const [TodaySalesTotal, setTodaySalesTotal] = useState("");
  const [driverDeliveredOrders, setDriverDeliveredOrders] = useState("");
  const [googleAPIKey, setGoogleAPIKey] = useState(
    "AIzaSyA1w-xcGh3_TshBws9-9pizeX9Q-2EX0_w"
  );
  const [global_date, setGlobalDateFormat] = useState("");
  const [global_currency, setGlobalCurrency] = useState("");
  const [driver_green_image, setDriverGreenImage] = useState("");
  const [driver_yellow_image, setDriverYellowImage] = useState("");
  const [driver_red_image, setDriverRedImage] = useState("");
  const [active_drivers_count, setActiveDriversCount] = useState("");
  const [active_products_count, setActiveProductsCount] = useState("");
  const [customer_profile, setCustomerProfile] = useState("");
  const [graph_type, setGraphType] = useState("daily");
  const [order_type, setOrderType] = useState("");
  const [graph_options, setGraphOptions] = useState([]);
  const [currentSelectedLocation, setCurrentSelectedLocation] = useState("");
  let sales_data = [];
  var order_list = [];
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };
  const defaultCenter = {
    lat: 9.0820,
    lng: 8.6753,
  };
  useEffect(async (props) => {
    onLoadFunction();
  }, []);
  const onLoadFunction = async (e) => {
    console.log("onLoadFunction",e)
    var startDate = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    var endDate = new Date();
    setDateRange([startDate, endDate]);
    var localSettings = await setLocalizationSettings();
    await setGlobalDateFormat(localSettings?.[0]);
    await setGlobalCurrency(localSettings?.[1]);
    var projectSettings = await apiRequest(
      [],
      Url.BASE_URL + "/projectSettings",
      "get"
    );
    setGoogleAPIKey(projectSettings.data.extracted[0].googleSetting.api_key);
    setDriverGreenImage(projectSettings.data.extracted[0].driver_green_image);
    setDriverYellowImage(projectSettings.data.extracted[0].driver_yellow_image);
    setDriverRedImage(projectSettings.data.extracted[0].driver_red_image);
    // setInterval(async() => {
    //     var drivergeoinfo = await apiRequest([], 'https://delapi.ng.jumpgrowth.com/api/driver-geolocations', 'get');
    //     const latlngs = drivergeoinfo.data;
    //     setMarkers(latlngs);
    // }, 10000);
    // setInterval(async () => { setCenterPoint(latlngs[Math.floor(Math.random()*latlngs.length)])}, 1000);
    var drivergeoinfo = await apiRequest(
      [],
      Url.API_URL + "driver-geolocations",
      "get"
    );
    const latlngs = drivergeoinfo.data;
    setMarkers(latlngs);
    const payload = {
      locationId: e
    };
    var ss = await apiRequest(payload, Url.BASE_URL + "/weekly-graph", "post");
    var sampleData = ss?.data?.SalesGraph?.length > 0 ? ss?.data?.SalesGraph : [{ label: "No Data", y: 0 }]
    var options = getSalesGraphObj(
      sampleData,
      localSettings?.[1] ? localSettings?.[1] : ""
    );
    // setGraphType("daily");
    setGraphTotalData(options);
    setGraphOptions(options);
    setOptions(options);
    setWeeklyGraph(options);
    getOrdersTotal();
    getCustomers();
    getLocations();
    // await apiRequest({userId : localStorage.user}, Url.BASE_URL+'/getRole','post');
    var todaySalesTotal = await apiRequest(
      { date: new Date().toISOString().slice(0, 10) },
      Url.BASE_URL + "/day-wise-sales",
      "post"
    );
    // setTodaySalesTotal(todaySalesTotal.data.salesTotal);
    var activedriversCount = await apiRequest(
      [],
      Url.BASE_URL +
        '/Drivers/count?where={"approval_status":"approved", "id_deleted":' +
        false +
        "}",
      "get"
    );
    setActiveDriversCount(activedriversCount?.data?.count);
    if (localStorage.getItem("role") === "locationmanager") {
      const activeproductsCount = await apiRequest(
          [],
          Url.BASE_URL +
          '/Products?filter={"where":{"id_deleted":false,"enable":true},"include":{"relation":"location"}}',
          "get"
      );
      
      const locations_assigned = localStorage.getItem("locations").split(",");
      let productsCount = 0;
      
      activeproductsCount.data.forEach(product => {
          if (product.location.some(loc => locations_assigned.includes(loc.id))) {
              productsCount++;
          }
      });
  
      setActiveProductsCount(productsCount);
  } else {
      const activeproductsCount = await apiRequest(
          [],
          Url.BASE_URL + '/Products/count?where={"id_deleted":false,"enable":true}',
          "get"
      );
      
      setActiveProductsCount(activeproductsCount.data.count);
  }
  
  };
  const getDrivers = async (driver_endpoint) => {
    var get_drivers = await apiRequest([], driver_endpoint, "get");
    if (
      get_drivers == null ||
      get_drivers == undefined ||
      get_drivers.data === "{}" ||
      Object.entries(get_drivers.data).length === 0 ||
      get_drivers === null ||
      get_drivers === undefined ||
      get_drivers.length <= 0 ||
      get_drivers.data.length <= 0
    ) {
      show_mesage("No records found");
    } else {
      let temp_Product = get_drivers.data;
      setDrivers(temp_Product);
    }
  };
  const handleEditOrderShow = async (item) => {
    var orderId = item.orderId;
    var order = await apiRequest(
      {},
      Url.BASE_URL + "/Orders/" + orderId,
      "get"
    );
    var driver_side_status = order.data.driver_side_status;
    if (
      driver_side_status == "picked" ||
      driver_side_status == "delivered" ||
      driver_side_status == "accepted" ||
      driver_side_status == "rejected"
    ) {
      alert(
        "This order is already picked up. You cannot make any changes to this order anymore."
      );
      setEdit(false);
      return false;
    } else {
      setEdit(true);
      setOrderDetailId(item.id);
      if (item.product != null && item.product != undefined) {
        setProductImage(item?.product?.image);
        setProductName(item.product?.name);
        setProductQuantity(item.quantity);
        setProductPrice(item.product?.pricings[0].unit_price);
      }
    }
  };
  const handleAssignDriverShow = () => {
    let drivers_endpoint =
      Url.DRIVERS +
      '?filter={"where":{"is_available":"green","id_deleted":' +
      false +
      ',"is_account_deleted":' +
      false +
      "}}";
    getDrivers(drivers_endpoint);
    var checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    ).length;
    if (checkboxes == 0) {
      alert("please select orders that you want to assign to driver!!");
      return false;
    }
    let temp = [...drivers_data];
    temp.forEach((element) => {
      element.checked = false;
    });
    setAssgin(true);
  };
  const handleAssignDriverClose = () => {
    setAssgin(false);
  };
  const handleEditOrderClose = () => {
    setEdit(false);
  };
  const incrementQuantity = async (orderId, productprice, orderDetailId) => {
    if (product_quantity > 0) {
      let quantity = product_quantity + 1;
      if (quantity > 0) {
        var order = await apiRequest(
          {},
          Url.BASE_URL + "/Orders/" + orderId,
          "get"
        );
        var orderTotal = order.data.order_total;
        var itemTotal = order.data.item_total;
        const apiPostHeaders = {
          "Content-Type": "application/json",
        };
        await axios({
          method: "post",
          url: Url.BASE_URL + "/banking/charge/card",
          timeout: 1000 * 5,
          headers: apiPostHeaders,
          data: {
            options: {
              email: email,
            },
            amount: orderTotal + productprice,
            card: order?.card?.id,
            currency: "inr",
          },
        })
          .then(async (response) => {
            if (response?.data?.response?.charge_id != undefined) {
              var charge_id = response.data.response.charge_id;
              await apiRequest(
                {
                  id: orderId,
                  order_total: orderTotal + productprice,
                  item_total: itemTotal + productprice,
                  charge_id: charge_id,
                },
                Url.BASE_URL + "/Orders",
                "patch"
              );
              await apiRequest(
                {
                  id: orderDetailId,
                  quantity: quantity,
                },
                Url.BASE_URL + "/OrderDetails",
                "patch"
              );
              LoadOrders();
              setProductQuantity(quantity);
            } else {
              alert("payment failed, please try again!!");
              setEdit(false);
            }
          })
          .catch((err) => {
            alert("payment failed, please try again!!");
          });
      } else {
        alert("Product quantity cannot be zero!!");
        return false;
      }
    }
  };
  const LoadOrders = async () => {
    handleCallback(0);
    var orderApiCall = await apiRequest(
      {},
      Url.UPDATEORDERS +
          '?filter={"order":"id DESC","where":{"checkout":true,"id":"' +
          orderId +
          '"},"include":[{"relation":"orderDetails","scope":{"include":[{"relation":"product","scope":{"include":"pricings"}},{"relation":"addOns"}]}},{"relation":"location","scope":{"include":[{"relation":"address"}]}},{"relation":"customer"},{"relation":"address"},{"relation":"drivers"},{"relation":"status"},{"relation":"tip"}]}',
      "get"
  );
    setProducts(orderApiCall.data[0].orderDetails);
    setOrderTotal(orderApiCall.data[0].order_total);
    setItemTotal(orderApiCall.data[0].item_total);
  };
  const updateOrderQuantity = async () => {
    let s = {
      id: order_detail_id,
      quantity: product_quantity,
    };
    var update = await apiRequest(s, Url.UPDATE_ORDER_DETAILS, "patch");
    if (update.error) {
      errornotify("'Failed', response.error.message");
      return;
    } else {
      notify("Updated successfully");
      setEdit(false);
    }
  };
  const decrementQuantity = async (orderId, productprice, orderDetailId) => {
    if (product_quantity > 0) {
      let quantity = product_quantity - 1;
      if (quantity > 0) {
        var order = await apiRequest(
          {},
          Url.BASE_URL + "/Orders/" + orderId,
          "get"
        );
        var orderTotal = order.data.order_total;
        var itemTotal = order.data.item_total;
        const apiPostHeaders = {
          "Content-Type": "application/json",
        };
        await axios({
          method: "post",
          url: Url.BASE_URL + "/banking/charge/card",
          timeout: 1000 * 5,
          headers: apiPostHeaders,
          data: {
            options: {
              email: email,
            },
            amount: orderTotal - productprice,
            card: order?.card?.id,
            currency: "inr",
          },
        })
          .then(async (response) => {
            if (response?.data?.response?.charge_id != undefined) {
              var charge_id = response.data.response.charge_id;
              await apiRequest(
                {
                  id: orderId,
                  order_total: orderTotal - productprice,
                  item_total: itemTotal - productprice,
                  charge_id: charge_id,
                },
                Url.BASE_URL + "/Orders",
                "patch"
              );
              await apiRequest(
                {
                  id: orderDetailId,
                  quantity: quantity,
                },
                Url.BASE_URL + "/OrderDetails",
                "patch"
              );
              LoadOrders();
            } else {
              errornotify("payment failed!!");
              setEdit(false);
            }
            LoadOrders();
            setProductQuantity(quantity);
          })
          .catch((err) => {
            errornotify("payment failed!!");
          });
      } else {
        alert("Product quantity cannot be zero!!");
        return false;
      }
    }
  };
  const notify = (message) => {
    toast.success(message);
  };
  const errornotify = (message) => {
    toast.error(message);
  };
  const handleShow = (item, type) => {
    if (type === "order") {
      setdriverInfo(item.drivers);
      setOrderDetail(true);
      setDropOffImage(item.dropoff_image);
      setTip(item?.tip);
      setShow(false);
      setOrderId(item.id);
      setItemTotal(item.item_total.toFixed(2));
      setOrderType(item?.type_of_order);
      setOrderTotal(item?.order_total.toFixed(2));
      setDriverSideStatus(item.driver_side_status);
      setTaxTotal(item?.tax_total?.toFixed(2));
      setDeliveryAmount(parseFloat(item?.delivery_charge)?.toFixed(2));
      setOfferAmount(item?.offer_amount?.toFixed(2));
      setName(
        item?.customer?.first_name == undefined
          ? item?.customer?.firstname
          : item?.customer?.first_name
      );
      setCustomerProfile(
        item?.customer?.profile_image == undefined ||
          item?.customer?.profile_image == ""
          ? "./img-placeholder.png"
          : item?.customer?.profile_image
      );
      if (item.customer != null && item.customer != undefined) {
        // if (item.customer.username != null && item.customer.username != undefined) {
        //     setName(item?.customer?.first_name == undefined ? item?.customer?.firstname : item?.customer?.first_name);
        // }
        setPhone(item.customer.mobile);
        setEmail(item.customer.email);
        setAddressName(item.customer.address);
      }
      if (item.type_of_order != null && item.type_of_order != undefined) {
        setType(item.type_of_order);
      }
      setOrderNo(item?.orderNo);
      sheduledDate(item?.selected_date);
      orderedDate(item?.ordered_date);
      setInstructions(item?.instructions);
      setNote(item.note);
      if (item.status != null && item.status != undefined) {
        setStatusus(item.status.order_status[0]);
      }
      if (item.orderDetails != null && item.orderDetails != undefined) {
        setProducts(item.orderDetails);
      }
      if (item.address != null && item.address != undefined) {
        setAddressName(item.address.text);
      }
    } else if (type === "eta") {
      setOrderDetail("");
      setShow(true);
      setOrderId(item.id);
      setEta(item.eta);
    }
  };
  const handleClose = async () => {
    setShow(false);
    let update_eta = {
      id: orderId,
      eta: eta,
    };
    let update_order_response = await apiRequest(
      update_eta,
      Url.UPDATEORDERS,
      "patch"
    );
    if (update_order_response.error) {
      errornotify("'Failed', response.error.message");
      return;
    } else {
      notify("Updated successfully");
      handleCallback(0);
      setEta("");
      getOrdersTotal();
      onLoadFunction();
    }
  };
  async function driverInformation(driverId) {
    var apiBody = {
      driverId: driverId,
      days: "nodays",
    };
    var driverDeliveredOrders = await apiRequest(
      apiBody,
      Url.BASE_URL + "/driver-ongoing-orders",
      "post"
    );
    var deliveredOrdersCount = driverDeliveredOrders.data.length;
    setDriverDeliveredOrders(deliveredOrdersCount);
    if (driverDeliveredOrders) {
      setInfoWindowStatus(driverId);
    }
  }
  const handleCallback = async (data) => {
    var tempId = data;
    onLoadFunction(tempId);
    setCurrentSelectedLocation(tempId);
    setOrders("");
    setDrivers("");
    setOrdersData("");
    setOrderTotal("");
    setPendingOrders("");
    setAccpectedOrders("");
    setCompletedOrders("");
    if (data == 0 || data.length <= 0) {
      endpoints =
        Url.UPDATEORDERS +
        '?filter={"order":"ordered_date DESC","where":{"checkout":' +
        true +
        '},"include":[{"relation":"orderDetails","scope":{"include":[{"relation":"product","scope":{"include":"pricings"}},{"relation":"addOns"}]}},{"relation":"status"},{"relation":"location"},{"relation":"customer"},{"relation":"address"},{"relation":"drivers"},{"relation":"status"},{"relation":"tip"}]}';
    } else if (data != 0 && data.length != 0) {
      endpoints =
        Url.UPDATEORDERS +
        '?filter={"order":"ordered_date DESC","where":{"locationId":"' +
        data +
        '","checkout":' +
        true +
        '},"include":[{"relation":"orderDetails","scope":{"include":[{"relation":"product","scope":{"include":"pricings"}},{"relation":"addOns"}]}},{"relation":"status"},{"relation":"location"},{"relation":"customer"},{"relation":"address"},{"relation":"drivers"},{"relation":"status"},{"relation":"tip"}]}';
    }
    var get_staff = await apiRequest([], endpoints, "get");
    if (
      get_staff == null ||
      get_staff == undefined ||
      get_staff.length <= 0 ||
      get_staff.data.length <= 0
    ) {
      data = [];
      show_mesage("No records found");
      return;
    } else {
      data = [];
      let drivers_geo = [];
      let temp = get_staff.data;
      let pendingcount = 0;
      let acceptedcount = 0;
      let completedcount = 0;
      var totalDeliveredOrderTotal = 0;
      if (localStorage.getItem("role") == "locationmanager") {
        var locations_assigned = localStorage.getItem("locations").split(",");
        debugger
        temp.forEach(function (post, i) {
          var locationExists = locations_assigned.includes(post.locationId);
          if (post.ordered_date != null && post.ordered_date != undefined) {
            data.push(post.order_total);
          }
          if (
            post.status?.order_status[0] == "Pending" &&
            locationExists == true
          ) {
            pendingcount++;
          }
          if (
            post.status?.order_status[0] == "Accepted" &&
            locationExists == true
          ) {
            acceptedcount++;
          }
          if (
            post.status?.order_status[0] == "Delivered" &&
            locationExists == true
          ) {
            completedcount++;
          }
        });
      } else {
        const today = new Date();
today.setHours(0, 0, 0, 0); // Set the time to midnight for accurate date comparison

temp.forEach(function (post) {
    const orderedDate = new Date(post.ordered_date);
    orderedDate.setHours(0, 0, 0, 0); // Set the time to midnight for accurate date comparison
    if (post.status?.order_status[0] === "Pending") {
      pendingcount++;
  }
  if (post.status?.order_status[0] === "Accepted") {
      acceptedcount++;
  }
  if (post.status?.order_status[0] === "Delivered") {
      completedcount++;
  }
    if (orderedDate.getTime() === today.getTime()) { // Check if the order date is today
        if (post.ordered_date != null && post.ordered_date != undefined) {
            data.push(post.order_total);
        }
        if (post.status?.order_status[0] === "Delivered") {
            totalDeliveredOrderTotal += post.order_total;
        }
    }
});
      }
     
      if (tempId !== "0") {
        try {
            const endpoints = Url.BASE_URL + '/admin-products';
            const get_prod_count = await apiRequest([], endpoints, 'post');
    
            // Filter products by tempId and remove duplicates based on name
            const productsDataLocs = get_prod_count.data.reduce((acc, product) => {
                if (product.location.some(loc => loc.id === tempId)) {
                    const existingProduct = acc.find(p => p.name === product.name);
                    if (!existingProduct) {
                        acc.push(product);
                    }
                }
                return acc;
            }, []);
            setActiveProductsCount(productsDataLocs.length);
        } catch (error) {
            console.error('Error fetching and processing products:', error);
            // Handle error as needed
        }
    }else{
      const activeproductsCount = await apiRequest(
        [],
        Url.BASE_URL + '/Products/count?where={"id_deleted":false,"enable":true}',
        "get"
    );
    
    setActiveProductsCount(activeproductsCount.data.count);
    }
      setPendingOrders(pendingcount); 
      setAccpectedOrders(acceptedcount);
      setCompletedOrders(completedcount);
      setTodaySalesTotal(totalDeliveredOrderTotal);
      var recentOrders = [];
      let nowDate = new Date().toISOString().split("T")[0];
      for (var i = 0; i < get_staff.data.length; i++) {
        if (get_staff.data[i].ordered_date.split("T")[0] == nowDate) {
          recentOrders.push(get_staff.data[i]);
        }
      }
      let temp_Product = recentOrders.slice(0, 5);
      setOrders(temp_Product);
      temp_Product.forEach(function (post, i) {
        if (
          post.drivers != null &&
          post.drivers != undefined &&
          post.drivers.geolocation != null &&
          post.drivers.geolocation != undefined
        ) {
          drivers_geo.push(post.drivers);
        }
        if (post.order_total != null && post.order_total != undefined) {
          orderamount = orderamount + post.order_total;
        }
        if (post.ordered_date != null && post.ordered_date != undefined) {
          recent_orders.push(post);
        }
      });
      let driv = drivers_geo;
      setOrderedDrivers(driv);
      function compare(a, b) {
        if (a.ordered_date > b.ordered_date) {
          return -1;
        }
        if (a.ordered_date < b.ordered_date) {
          return 1;
        }
        return 0;
      }
      recent_orders.sort(compare);
      recent_orders.forEach(function (post, i) {
        if (i <= 4) {
          recent_customers.push(post);
        }
      });
      if (localStorage.getItem("role") == "businessowner") {
        // setOrders(recent_orders);
      } else {
        var locationsassigned = localStorage.getItem("locations").split(",");
        var locationManagerOrders = [];
        for (var i = 0; i < locationsassigned.length; i++) {
          for (var j = 0; j < recent_orders.length; j++) {
            if (recent_orders[j].locationId == locationsassigned[i]) {
              locationManagerOrders.push(recent_orders[j]);
            }
          }
        }
        var sortOrders = locationManagerOrders.sort(function (a, b) {
          return b.ordered_date > a.ordered_date;
        });
        // setOrders(sortOrders);
      }
    }
  };
  const handleCheckbox = (item, index) => {
    order_list = order_data;
    let temp = [...orders];
    if (
      temp[index].enable === false ||
      temp[index].enable === null ||
      temp[index].enable === undefined ||
      temp[index].enable == ""
    ) {
      let ss = true;
      temp[index].enable = ss;
      item.enable = ss;
      order_list.push(temp[index].id);
      let array = [];
      for (var i = 0; i < order_list.length; i++) {
        array.push(order_list[i]);
      }
    } else if (temp[index].enable === true || temp[index].enable === "true") {
      let st = false;
      item.enable = st;
      temp[index].enable = st;
      for (var i = 0; i < order_list.length; i++) {
        if (order_list[i].id === temp[index].id) {
          order_list.splice(i, 1);
          i--;
        }
      }
    }
    setOrderList(order_list);
  };
  const getOrdersTotal = async () => {
    var get_products = await apiRequest(
      [],
      Url.BASE_URL + "/today-orders",
      "post"
    );

    setOrders("");

    if (
      get_products == null ||
      get_products == undefined ||
      get_products.data === "{}" ||
      Object.entries(get_products.data).length === 0 ||
      get_products === null ||
      get_products === undefined ||
      get_products.length <= 0 ||
      get_products.data.length <= 0
    ) {
      setGraphData("sales graph");
      show_mesage("No records found");
    } else {
      var recentOrders = get_products.data;
      let temp_Product = recentOrders;
      let drivers_geolocation = [];
      // setOrders(temp_Product)
      // setOrdersData(temp_Product);
      let status = [];
      status.push("online");
      // temp_Product.forEach(function (post, i) {
      //     if (post.drivers != null && post.drivers != undefined && post.drivers.geolocation != null && post.drivers.geolocation != undefined) {
      //         drivers_geolocation.push(post.drivers)
      //     }
      //     if (post.order_total != null && post.order_total != undefined) {
      //         orderamount = orderamount + post.order_total
      //     }
      //     if (post.ordered_date != null && post.ordered_date != undefined) {
      //         var dates = new Date(post.ordered_date)
      //         if (post.order_total != null && post.order_total != undefined) {
      //             let s = { x: new Date(dates.getFullYear(), dates.getMonth()), y: post.order_total }
      //             sales_data.push(s)
      //         }
      //         recent_orders.push(post)
      //     }
      // })
      setGraphData("sales graph");
      let driv = drivers_geolocation;
      setOrderedDrivers(driv);
      function compare(a, b) {
        if (a.ordered_date > b.ordered_date) {
          return -1;
        }
        if (a.ordered_date < b.ordered_date) {
          return 1;
        }
        return 0;
      }
      recent_orders.sort(compare);
      recent_orders.forEach(function (post, i) {
        if (i <= 4) {
          recent_customers.push(post);
        }
      });
      function getUnique(arr, comp) {
        const unique = arr
          .map((e) => e[comp])
          .map((e, i, final) => final.indexOf(e) === i && i)
          .filter((e) => arr[e])
          .map((e) => arr[e]);
        return unique;
      }
      let result = getUnique(recent_customers, "id");
      // setOrders(result)
      // setOrdersData(result)
      if (localStorage.getItem("role") == "businessowner") {
        setOrders(get_products.data);
      } else {
        var locationsassigned = localStorage.getItem("locations").split(",");
        var locationManagerOrders = [];
        for (var i = 0; i < get_products.data.length; i++) {
          for (var j = 0; j < locationsassigned.length; j++) {
            if (get_products.data[i].locationId == locationsassigned[j]) {
              locationManagerOrders.push(get_products.data[i]);
            }
          }
        }
        // const locationManagerId = localStorage.getItem("currentUser");
        // const filteredOrders = get_products.data.filter(order => order.locationManagerId === locationManagerId);
       
        setOrders(locationManagerOrders);
      }
    }
  };
  const getCustomers = async () => {
    var get_response = await apiRequest(
      [],
      Url.BASE_URL + "/Customers/count",
      "get"
    );
    if (
      get_response == null ||
      get_response == undefined ||
      get_response.data === "{}" ||
      Object.entries(get_response.data).length === 0 ||
      get_response === null ||
      get_response === undefined ||
      get_response.length <= 0 ||
      get_response.data.length <= 0
    ) {
      show_mesage("No records found");
    } else {
      let temp_Product = get_response.data;
      setCustomerCount(temp_Product.count);
    }
  };
  const fitBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.map((place) => {
      bounds.extend(place);
      return place.driverId;
    });
    map.fitBounds(bounds);
  };
  const loadHandler = (map) => {
    // Store a reference to the google map instance in state
    setMapRef(map);
    // Fit map bounds to contain all markers
    fitBounds(map);
  };
  function LoadGoogleMap() {
    const { isLoaded } = useLoadScript({
      googleMapsApiKey: googleAPIKey,
    });

    return isLoaded ? (
      <GoogleMap
        onLoad={loadHandler}
        mapContainerStyle={mapStyles}
        zoom={2}
        center={defaultCenter}
      >
        {markers.length > 0 &&
          markers.map((item, i) => {
            const lat = parseFloat(item.lat);
            const lng = parseFloat(item.lng);
            // console.error(`Invalid lat/lng for marker at index ${i}:`, lat,lng);
            // Validate that lat and lng are numbers
            if (!isNaN(lat) && !isNaN(lng)) {
              if (item.is_available === "green") {
                item.icon = driver_green_image;
              } else if (item.is_available === "red") {
                item.icon = driver_red_image;
              } else {
                item.icon = driver_yellow_image;
              }
    
              return (
                <Marker
                  key={i}
                  onClick={() => driverInformation(item.driverId)}
                  icon={{
                    url: item.icon,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                  position={{ lat: lat, lng: lng }}
                />
              );
            } else {
              // console.error(`Invalid lat/lng for marker at index ${i}:`, item);
              return null; // Skip rendering the invalid marker
            }
          })}
    
        {markers.length > 0 && InfoWindowStatus !== "" &&
          markers.map((item) => {
            const lat = parseFloat(item.lat);
            const lng = parseFloat(item.lng);
    
            // Validate that lat and lng are numbers
            if (!isNaN(lat) && !isNaN(lng) && item.driverId === InfoWindowStatus) {
              let status = "";
              if (item.is_available === "green") {
                status = "available";
              } else if (item.is_available === "yellow") {
                status = "delivering";
              } else {
                status = "offline";
              }
    
              return (
                <InfoWindow
                  key={item.id}
                  position={{ lat: lat, lng: lng }}
                  clickable={true}
                >
                  <div style={{ background: `white`, height: "100px" }}>
                    <div
                      style={{
                        display: "flex",
                        width: "200px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "200px",
                          width: "40px",
                          height: "40px",
                        }}
                        src={item.profile_image}
                        alt={item.first_name}
                      />
                      <h5 style={{ marginLeft: "-3px" }}>{item.first_name}</h5>
                    </div>
                    <p style={{ color: item.is_available }}>Status: {status}</p>
                    <div style={{ margin: "10px" }}>
                      <b>No of orders: {driverDeliveredOrders}</b>
                      <b>Delivering</b>
                    </div>
                  </div>
                </InfoWindow>
              );
            } else {
              console.error(`Invalid lat/lng for InfoWindow:`, item);
              return null; // Skip rendering the invalid InfoWindow
            }
          })}
      </GoogleMap>
    ) : (
      ""
    );
    

    return isLoaded ? (
      <GoogleMap
        onLoad={loadHandler}
        mapContainerStyle={mapStyles}
        zoom={2}
        center={defaultCenter}
      >
        {markers.length > 0
          ? markers.map((item) => {
              if (item.is_available == "green") {
                item.icon = driver_green_image;
              } else if (item.is_available == "red") {
                item.icon = driver_red_image;
              } else {
                item.icon = driver_yellow_image;
              }
              return (
                <Marker
                  onClick={() => driverInformation(item.driverId)}
                  icon={{
                    url: item.icon,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                  position={{ lat: item.lat, lng: item.lng }}
                />
              );
            })
          : ""}
        {markers.length > 0 && InfoWindowStatus != ""
          ? markers.map((item) => {
              var status = "";
              if (item.driverId == InfoWindowStatus) {
                if (item.is_available == "green") {
                  status = "available";
                } else if (item.is_available == "yellow") {
                  status = "delivering";
                } else {
                  status = "offline";
                }
                return (
                  <InfoWindow
                    position={{ lat: item.lat, lng: item.lng }}
                    clickable={true}
                    onCloseClick={() => setInfoWindowStatus(false)}
                  >
                    <div style={{ background: `white`, height: "100px" }}>
                      <div
                        style={{
                          display: "flex",
                          width: "200px",
                          justifyContent: "space-between",
                        }}
                      >
                        <img
                          style={{
                            "border-radius": "200px",
                            width: "40px",
                            height: "40px",
                          }}
                          src={item.profile_image}
                        />
                        <h5 style={{ "margin-left": "-74px" }}>
                          {item.first_name}
                        </h5>
                        <br />
                        <br />
                      </div>
                      <p style={{ color: item.is_available }}>
                        Status : {status}
                      </p>
                      <div style={{ margin: "10px" }}>
                        <b>No of orders: {driverDeliveredOrders}</b>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <b>Delivering</b>
                      </div>
                    </div>
                  </InfoWindow>
                );
              }
            })
          : ""}
      </GoogleMap>
    ) : (
      ""
    );
  }
  const getLocations = async () => {
    var get_response = await apiRequest(
      [],
      Url.LOCATIONSCOUNT + '?where={"id_deleted":' + false + "}",
      "get"
    );
    if (
      get_response == null ||
      get_response == undefined ||
      get_response.data === "{}" ||
      Object.entries(get_response.data).length === 0 ||
      get_response === null ||
      get_response === undefined ||
      get_response.length <= 0 ||
      get_response.data.length <= 0
    ) {
      show_mesage("No records found");
    } else {
      let temp_Product = get_response.data;
      setLocationsCount(temp_Product.count);
    }
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </a>
  ));
  const _onChangeDropdown = async (type, text, orderId) => {
    if (type === "value") {
      if (text !== "") {
        let status = [];
        status.push(text);
        let update_status = {
          orderId: orderId,
          order_status: status,
          status: [1],
          active: ["active"],
        };
        let location_response = await apiRequest(
          update_status,
          Url.UPDATEORDERSTATUS,
          "post"
        );
        if (location_response.error) {
          errornotify("'Failed', response.error.message");
          return;
        } else {
          status = [];
          let upder_order = {
            id: orderId,
            orderstatusId: location_response.data.data.id,
          };
          await apiRequest(upder_order, Url.UPDATEORDERS, "patch");
          setStatusus(text);
          notify("Updated successfully");
          getOrdersTotal();
          handleCallback(0);
        }
      }
    }
  };
  const handleOrderDetailClose = async () => {
    setOrderDetail(false);
  };
  const filterDateRange = async (daterange, orders) => {
    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    function countDays(date1, date2) {
      var one_day = 1000 * 60 * 60 * 24;
      return Math.ceil((date1.getTime() - date2.getTime()) / one_day);
    }
    var minDate = new Date(daterange[0]);
    var maxDate = new Date(daterange[1]);

    function addDays(myDate, days) {
      return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
    }

    maxDate = addDays(maxDate, 1);

    var orders = await apiRequest(
      {
        minDate: minDate.toISOString(), // Convert to ISO string
        maxDate: maxDate.toISOString(), // Convert to ISO string
        location: currentSelectedLocation
      },
      Url.BASE_URL + "/sales-graph-by-date-range",
      "post"
    );

    var sampleData = orders.data.models.length > 0 ? orders.data.models : [{ label: "No Data", y: 0 }]
    var result = [];
    sampleData.reduce(function (res, value) {
      if (!res[value.label]) {
        res[value.label] = {
          label: value.label,
          qty: 0,
          y: 0,
          year: value.year,
          month: value.month,
        };
        result.push(res[value.label]);
      }
      if (value.y != undefined) {
        res[value.label].y += value.y;
      }
      return res;
    }, {});
    function date_sort(a, b) {
      return new Date(a.label).getTime() - new Date(b.label).getTime();
    }
    var options = getSalesGraphObj(
      result,
      global_currency ? global_currency : ""
    );
    options.data[0].dataPoints = options.data[0].dataPoints.sort(date_sort);
    setGraphOptions(options);
    setOptions(options);
  };
  const weeklyOrders = () => {
    function weeksort(a, b) {
      return a - b;
    }
    var sortedOrders = graph_options?.data[0]?.dataPoints.reduce(
      (acc, date) => {
        const WeekNumber = `${moment(date.label).week()}`;
        if (!acc[WeekNumber]) {
          acc[WeekNumber] = [];
        }
        acc[WeekNumber].push({
          weeknumber: WeekNumber,
          label: date.label,
          y: date.y,
        });
        return acc;
      },
      {}
    );
    var sort2 = [];
    for (var key in sortedOrders) {
      if (sortedOrders.hasOwnProperty(key)) {
        for (var i = 0; i < sortedOrders[key].length; i++) {
          var dateWeek = new Date(sortedOrders[key][0].label);
          var firstday = new Date(
            dateWeek.setDate(dateWeek.getDate() - dateWeek.getDay())
          );
          var lastday = new Date(
            dateWeek.setDate(dateWeek.getDate() - dateWeek.getDay() + 6)
          );
          var firstday1 = new Date();  // Replace this with your actual date object
          var lastday1 = new Date();   // Replace this with your actual date object
          
          var labelDatefirst = firstday1.toISOString().slice(0, 10);
          var labelDateLast = lastday1.toISOString().slice(0, 10);
          sort2.push({
            // label: labelDatefirst + " to " + labelDateLast,
            label: labelDatefirst,
            y: sortedOrders[key][i].y,
            serial: key,
          });
        }
      }
    }
    var result = [];
    sort2.reduce(function (res, value) {
      if (!res[value.label]) {
        res[value.label] = { label: value.label, y: 0, serial: value.serial };
        result.push(res[value.label]);
      }
      res[value.label].y += value.y;
      return res;
    }, {});
    var options = getSalesGraphObj(
      result,
      global_currency ? global_currency : ""
    );
    options.data[0].dataPoints = options.data[0].dataPoints.sort(weeksort);
    setOptions(options);
  };
  const monthlyOrders = () => {
    const grouped = graph_options.data[0].dataPoints.reduce((acc, val) => {
      if (acc[val.month + "/" + val.year]) {
        acc[val.month + "/" + val.year].push(val.y);
      } else {
        acc[val.month + "/" + val.year] = [val.y];
      }
      return acc;
    }, {});
    var optionsData = [];
    for (var key in grouped) {
      if (grouped.hasOwnProperty(key)) {
        optionsData.push({
          label: key,
          y: grouped[key].reduce(function (acc, val) {
            return acc + val;
          }, 0),
        });
      }
    }
    if (optionsData[0].label == "undefined/undefined") {
      var date = new Date();
      var month = date.getMonth();
      optionsData[0].label = ++month + "/" + date.getFullYear();
    }
    var options = getSalesGraphObj(
      optionsData,
      global_currency ? global_currency : ""
    );
    setOptions(options);
  };
  const handleDriverCheckbox = async (data) => {
    let temp = [...drivers_data];
    temp.forEach((element) => {
      if (element.id == data.id) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
    setDrivers(temp);
    setDriverId(data.id);
  };
  const assignOrders = async () => {
    var deliveredOrder = await apiRequest(
      {
        id: order_data,
      },
      Url.BASE_URL + "/check-order-assigned",
      "post"
    );
    if (deliveredOrder.data.deliveredFlag == true) {
      alert("Select order is already delivered by the driver!!");
      return false;
    }
    let s = {
      id: order_data,
      driverId: driver_id,
    };
    var update = await apiRequest(s, Url.UPDATE_ORDER, "patch");
    var updateAssign = await apiRequest(
      {
        id: order_data[0],
        assign: "manual",
        driver_side_status: "accepted",
      },
      Url.BASE_URL + "/Orders",
      "patch"
    );
    if (update.error) {
      errornotify("'Failed', response.error.message");
      return;
    } else {
      notify("Updated successfully");
      setAssgin(false);
      handleCallback(0);
    }
  };
  const recentOrderView = (orders) => {
    return (
      <OrdersList
        values={{
          data: orders,
          filtered_orders: orders,
          category1: category1,
          view_show_msg: view_show_msg,
          handleShow: handleShow,
          global_currency: global_currency,
          handleCheckbox: handleCheckbox,
          _onChangeDropdown: _onChangeDropdown,
          handleAssignDriverShow: handleAssignDriverShow,
          CustomToggle: CustomToggle,
          status_value: status_value,
        }}
      />
    );
  };
  return (
    <div>
      <html lang="en">
        <div class="sticky">
          <Header parentCallback={(params) => handleCallback(params)} />
        </div>
        <body>
          <div class="middle_block">
            <Sidebar />
            <div class="main_content">
              <div class="container-fluid">
                <div class="dash_stats">
                  <div class="sec_title">
                    <h2 class="head_sty1">Dashboard</h2>
                  </div>
                  <Statistics
                    values={{
                      pending_count: pending_count ? pending_count : 0,
                      accpected_count: accpected_count ? accpected_count : 0,
                      completed_count: completed_count ? completed_count : 0,
                      customer_count: customer_count ? customer_count : 0,
                      TodaySalesTotal: TodaySalesTotal
                        ? TodaySalesTotal.toFixed(2)
                        : 0,
                      locations_count: locations_count ? locations_count : 0,
                      active_drivers_count: active_drivers_count
                        ? active_drivers_count
                        : 0,
                      active_products_count: active_products_count
                        ? active_products_count
                        : 0,
                    }}
                  />
                </div>
                <div class="recent_orders">
                  {orders.length > 0 ? (
                    <div class="sec_title">
                      <h2 class="head_sty1">Recent Orders</h2>
                      <a href="orders" class="btn_sty_1 sm_btn1">
                        View all
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  <div class="r_order_box">
                 {orders.slice(0, 5).length > 0 ? recentOrderView(orders.slice(0, 5)) : ""}
                    <OrdersETA
                      values={{
                        show: show,
                        handleClose: handleClose,
                        eta: eta,
                        setEta: setEta,
                      }}
                    />
                    <Modal
                      class="modalform"
                      size="lg"
                      class="modal-dialog modal-dialog-centered modal-lg"
                      style={{ width: "1000px;", "padding-top": "150px" }}
                      fullscreen="true"
                      show={order_detail}
                      onHide={handleOrderDetailClose}
                    >
                      <div class="modal-content">
                        <Modal.Header closeButton>
                          <Modal.Title>Order Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div class="modal-body">
                            <div class="r_order_box">
                              <div class="r_order_top">
                                <div class="img_box">
                                  <img src={customer_profile} alt="" />
                                </div>
                                <div class="r_order_info">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <h2 class="r_name">
                                        <span>{name}</span>
                                      </h2>
                                      <h4>
                                        Phone: <span>{phone}</span>
                                      </h4>
                                      <h4>
                                        Email: <span>{email}</span>
                                      </h4>
                                      <h4>
                                        Type: <span>{type}</span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="r_order_bottom">
                                <div class="r_dateandtime">
                                  <Moment
                                    format={
                                      global_date
                                        ? global_date
                                        : " DD MMM  YYYY-h:mm A"
                                    }
                                  >
                                    {ordered_date}
                                  </Moment>
                                </div>
                                <div class="r_eta">
                                  Order ID: <span>{orderno}</span>
                                </div>
                                <div class="r_status dropdown">
                                  <div class="status_selected">
                                    {statusus != null ? statusus : null}
                                    <Dropdown
                                      onSelect={(e) =>
                                        _onChangeDropdown("value", e, orderId)
                                      }
                                    >
                                      <Dropdown.Toggle as={CustomToggle} />
                                      <Dropdown.Menu
                                        size="sm"
                                        title={statusus}
                                        value={statusus}
                                      >
                                        <Dropdown.Item eventKey="Pending">
                                          Pending
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Accepted">
                                          Accepted
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Preparing">
                                          Preparing
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Prepared">
                                          Prepared
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item eventKey="On The Way">On The Way</Dropdown.Item> */}
                                        <Dropdown.Item eventKey="Cancelled">
                                          Cancelled
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Delivered">
                                          Delivered
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="odr_details_product">
                              <h3 class="oder_popup_headings">Products</h3>
                              {products.length > 0 ? (
                                products?.map((item, index) => {
                                  return (
                                    <div class="r_order_box r_order_box_product">
                                      <div class="r_order_top">
                                        <div class="img_box">
                                          {" "}
                                          {item.product?.image != null ? (
                                            <img
                                              src={item.product?.image}
                                              alt=""
                                            />
                                          ) : (
                                            <img src={category1} alt="" />
                                          )}
                                        </div>
                                        <div class="r_order_info">
                                          <div class="row">
                                            <div class="col-md-12">
                                              <h2 class="r_name">
                                                {item.product?.name}
                                              </h2>
                                            </div>
                                            {statusus == "Pending" ||
                                            statusus == "Accepted" ||
                                            driver_side_status == "waiting" ? (
                                              <div
                                                onClick={() =>
                                                  handleEditOrderShow(item)
                                                }
                                                class="col-md-6"
                                              >
                                                <h4>
                                                  <span
                                                    style={{
                                                      "margin-right": "10px",
                                                    }}
                                                  >
                                                    Quantity: {item.quantity}
                                                  </span>{" "}
                                                  {/* <a
                                                    href="#"
                                                    style={{
                                                      color: "#319F60s",
                                                    }}
                                                  >
                                                    <i class="icofont-pencil-alt-2"></i>{" "}
                                                    Edit
                                                  </a> */}
                                                </h4>
                                              </div>
                                            ) : (
                                              <div class="col-md-6">
                                                <h4>
                                                  <span
                                                    style={{
                                                      "margin-right": "10px",
                                                    }}
                                                  >
                                                    Quantity: {item.quantity}
                                                  </span>{" "}
                                                  <a
                                                    href="#"
                                                    style={{
                                                      color: "#319F60s",
                                                    }}
                                                  ></a>
                                                </h4>
                                              </div>
                                            )}
                                            <div class="col-md-6 r_align_right">
                                              <h3>
                                                <span
                                                  style={{
                                                    color: "#319F60",
                                                    "font-weight": "600",
                                                  }}
                                                >
                                                  {global_currency +
                                                    item.product_price *
                                                      item.quantity}
                                                </span>
                                              </h3>
                                            </div>
                                            <div class="col-md-12">
                                              {/* <p>
                                                Notes: Lorem ipsum dolor sit
                                                amet, consectetur adipiscing
                                                elit.
                                              </p> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="r_order_bottom">
                                        {item.addOns != undefined &&
                                        item.addOns != null &&
                                        item.addOns.length > 0
                                          ? item.addOns?.map((item, index) => {
                                              return (
                                                <ul class="order_addontag">
                                                  <li>
                                                    {item.name}{" "}
                                                    {global_currency +
                                                      item.price}
                                                  </li>
                                                </ul>
                                              );
                                            })
                                          : null}
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div
                                  align="center"
                                  style={{ "font-size": "16" }}
                                >
                                  {view_show_msg}
                                </div>
                              )}
                            </div>
                            <div class="ord_summary">
                              {/* <h3 class="oder_popup_headings">Summary</h3> */}
                              <div class="ord_summary">
                                <h3 class="oder_popup_headings">Summary</h3>
                                <div class="r_order_box r_order_summary">
                                  <div class="ord_box_wrap">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <div class="r_summary_info">
                                          <span>Subtotal: </span>{" "}
                                          <span>
                                            <strong>
                                              {global_currency + item_total}
                                            </strong>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="r_summary_info">
                                          <span>Tax: </span>{" "}
                                          <span>
                                            <strong>
                                              {global_currency + tax_total}
                                            </strong>
                                          </span>
                                        </div>
                                      </div>
                                      {order_type == "delivery" ? (
                                        <div class="col-md-6">
                                          <div class="r_summary_info">
                                            <span>Delivery Charge: </span>{" "}
                                            <span>
                                              <strong>
                                                {global_currency +
                                                  delivery_amount}
                                              </strong>
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div class="col-md-6">
                                        <div class="r_summary_info">
                                          <span>Offers: </span>{" "}
                                          <span>
                                            <strong>
                                              {offer_amount == undefined
                                                ? global_currency + "0.00"
                                                : global_currency +
                                                  offer_amount}
                                            </strong>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="r_order_bottom"
                                    style={{ display: "block" }}
                                  >
                                    <div class="row">
                                      <div class="col-md-6">
                                        <div
                                          class="r_summary_info"
                                          style={{ padding: "0" }}
                                        >
                                          <span>Total: </span>{" "}
                                          <span>
                                            <strong
                                              style={{
                                                color: "#000",
                                                "font-size": "18px",
                                              }}
                                            >
                                              {global_currency +
                                                parseFloat(order_total).toFixed(
                                                  2
                                                )}
                                            </strong>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div
                                          class="r_summary_info payment_method"
                                          style={{ padding: "0" }}
                                        >
                                          <span>Payment Method : </span>{" "}
                                          <span>
                                            <strong> &nbsp;Debit Card</strong>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {order_type == "delivery" ? (
                              <div class="ord_delivery_info">
                                <h3 class="oder_popup_headings">
                                  Delivery Information
                                </h3>
                                <div class="r_order_box r_order_summary">
                                  <div class="ord_box_wrap">
                                    <div class="row">
                                      <div class="col-md-4">
                                        {type == "delivery" ? (
                                          <div class="pro_stats_box">
                                            <h3>Delivery Address</h3>
                                            <p>
                                              <strong> </strong>
                                              {address_name}7
                                            </p>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div class="col-md-4">
                                        <div class="pro_stats_box">
                                          <h3>Delivery Instructions</h3>
                                          <p>{instructions}</p>
                                        </div>
                                      </div>
                                      {/* <div class="col-md-4">
                                                                                <div class="pro_stats_box">
                                                                                    <h3>Delivery Schedule</h3>
                                                                                    <p> <Moment format={global_date ? global_date : " DD MMM  YYYY-h:mm A"}>{sheduled_date}</Moment></p>
                                                                                </div>
                                                                            </div> */}
                                      {/* <div class="col-md-12">
                                                                                <div class="pro_stats_box" style={{ "margin-bottom": 0 }}>
                                                                                    <h3>Additional Notes</h3>
                                                                                    <p>{note}</p>
                                                                                </div>
                                                                            </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {driverInfo == undefined ||
                            driverInfo == "" ||
                            driverInfo == {} ||
                            driverInfo == [] ? (
                              ""
                            ) : (
                              <div class="ord_driver_info">
                                <h3 class="oder_popup_headings">
                                  Driver Information
                                </h3>
                                <div class="r_order_box r_order_summary">
                                  <div class="ord_box_wrap">
                                    <div class="driver_top_info">
                                      <div class="driver_pic">
                                        <img
                                          src={driverInfo?.profile_image}
                                          alt=""
                                        />
                                      </div>
                                      <div class="driver_content">
                                        <h3>{driverInfo?.first_name}</h3>
                                        {statusus == "Delivered" ? (
                                          <p>
                                            Tip Received:{" "}
                                            <strong>
                                              {tipData?.amount == undefined
                                                ? "0"
                                                : global_currency +
                                                  tipData?.amount}
                                            </strong>
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </Modal.Body>
                      </div>
                    </Modal>
                    <div class="r_order_box">
                      <div class="sales_graph">
                        <div class="sec_title">
                          <h2 class="head_sty1">Sales Graph</h2>
                          {graph_type == "daily" ? (
                            <div
                              style={{ "margin-left": "539px" }}
                              class="btn-group"
                            >
                              <button
                                style={{ background: "green", color: "#fff" }}
                                id="d1"
                                onClick={() => {
                                  document.getElementById(
                                    "d1"
                                  ).style.background = "green";
                                  document.getElementById("d1").style.color =
                                    "#fff";
                                }}
                                class="btn_graph"
                              >
                                Daily
                              </button>
                              {/* <button onClick={() => weeklyOrders()} class="btn_graph">Weekly</button>
                                                            <button onClick={() => monthlyOrders()} class="btn_graph">Monthly</button> */}
                            </div>
                          ) : (
                            ""
                          )}
                          {graph_type == "weekly" ? (
                            <div
                              style={{ "margin-left": "539px" }}
                              class="btn-group"
                            >
                              <button
                                style={{ background: "green", color: "#fff" }}
                                id="d2"
                                onClick={() => {
                                  filterDateRange([startDate, endDate], orders);
                                  document.getElementById(
                                    "d2"
                                  ).style.background = "green";
                                  document.getElementById("d2").style.color =
                                    "#fff";
                                  document.getElementById(
                                    "d3"
                                  ).style.background = "#fff";
                                  document.getElementById("d3").style.color =
                                    "#000";
                                }}
                                class="btn_graph"
                              >
                                Daily
                              </button>
                              <button
                                id="d3"
                                onClick={() => {
                                  weeklyOrders();
                                  document.getElementById(
                                    "d2"
                                  ).style.background = "#fff";
                                  document.getElementById("d2").style.color =
                                    "#000";
                                  document.getElementById(
                                    "d3"
                                  ).style.background = "green";
                                  document.getElementById("d3").style.color =
                                    "#fff";
                                }}
                                class="btn_graph"
                              >
                                Weekly
                              </button>
                              {/* <button onClick={() => monthlyOrders()} class="btn_graph">Monthly</button> */}
                            </div>
                          ) : (
                            ""
                          )}
                          {graph_type == "monthly" ? (
                            <div
                              style={{ "margin-left": "539px" }}
                              class="btn-group"
                            >
                              <button
                                id="d4"
                                style={{ background: "green", color: "#fff" }}
                                onClick={() => {
                                  filterDateRange([startDate, endDate], orders);
                                  document.getElementById(
                                    "d4"
                                  ).style.background = "green";
                                  document.getElementById("d4").style.color =
                                    "#fff";
                                  document.getElementById(
                                    "d5"
                                  ).style.background = "#fff";
                                  document.getElementById("d5").style.color =
                                    "#000";
                                  document.getElementById(
                                    "d6"
                                  ).style.background = "#fff";
                                  document.getElementById("d6").style.color =
                                    "#000";
                                }}
                                class="btn_graph"
                              >
                                Daily
                              </button>
                              <button
                                id="d5"
                                onClick={() => {
                                  weeklyOrders();
                                  document.getElementById(
                                    "d5"
                                  ).style.background = "green";
                                  document.getElementById("d5").style.color =
                                    "#fff";
                                  document.getElementById(
                                    "d4"
                                  ).style.background = "#fff";
                                  document.getElementById("d4").style.color =
                                    "#000";
                                  document.getElementById(
                                    "d6"
                                  ).style.background = "#fff";
                                  document.getElementById("d6").style.color =
                                    "#000";
                                }}
                                class="btn_graph"
                              >
                                Weekly
                              </button>
                              <button
                                id="d6"
                                onClick={() => {
                                  monthlyOrders();
                                  document.getElementById(
                                    "d6"
                                  ).style.background = "green";
                                  document.getElementById("d6").style.color =
                                    "#fff";
                                  document.getElementById(
                                    "d4"
                                  ).style.background = "#fff";
                                  document.getElementById("d4").style.color =
                                    "#000";
                                  document.getElementById(
                                    "d5"
                                  ).style.background = "#fff";
                                  document.getElementById("d5").style.color =
                                    "#000";
                                }}
                                class="btn_graph"
                              >
                                Monthly
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                          <div class="s_date_wrap">
                            <div style={{ marginTop: "9px" }}>
                              <DatePicker
                                placeholderText={
                                  new Date(
                                    new Date().getTime() - 7 * 86400000
                                  ).toLocaleDateString("en-GB") +
                                  "-" +
                                  new Date().toLocaleDateString("en-GB")
                                }
                                maxDate={new Date()}
                                isClearable
                                className="home-datepicker form-control"
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                  // alert(update[0]);
                                  setDateRange(update);
                                  if (
                                    update.length == 2 &&
                                    update[0] != null &&
                                    update[1] != null
                                  ) {
                                    var date1 = new Date(update[0]);
                                    var date2 = new Date(update[1]);
                                    var diffDays = parseInt(
                                      (date2 - date1) / (1000 * 60 * 60 * 24),
                                      10
                                    );
                                    // alert(diffDays);
                                    if (diffDays < 7) {
                                      // alert("daily");
                                      setGraphType("daily");
                                    }
                                    if (diffDays > 7 && diffDays < 30) {
                                      // alert("weekly");
                                      setGraphType("weekly");
                                      setTimeout(() => {
                                        document.getElementById(
                                          "d2"
                                        ).style.background = "green";
                                        document.getElementById(
                                          "d2"
                                        ).style.color = "#fff";
                                        document.getElementById(
                                          "d3"
                                        ).style.background = "#fff";
                                        document.getElementById(
                                          "d3"
                                        ).style.color = "#000";
                                      }, 200);
                                    }
                                    if (diffDays >= 30) {
                                      // alert(diffDays);
                                      // alert("monthly");
                                      setGraphType("monthly");
                                      setTimeout(() => {
                                        document.getElementById(
                                          "d4"
                                        ).style.background = "green";
                                        document.getElementById(
                                          "d4"
                                        ).style.color = "#fff";
                                        document.getElementById(
                                          "d5"
                                        ).style.background = "#fff";
                                        document.getElementById(
                                          "d5"
                                        ).style.color = "#000";
                                        document.getElementById(
                                          "d6"
                                        ).style.background = "#fff";
                                        document.getElementById(
                                          "d6"
                                        ).style.color = "#000";
                                      }, 200);
                                    }
                                    filterDateRange(update, orders);
                                  }
                                }}
                              />
                            </div>

                            {!endDate && (
                                    <i
                                      className="icofont-ui-calendar calendar_icon"
                                      style={{
                                        paddingLeft: 10,
                                        margin: "6px -140px 0px 0px",
                                      }}
                                      htmlFor="fromdate"
                                    ></i>
                                  )}
                          </div>
                        </div>
                        {  graph_data.length > 0 ? (
                          <div>
                            {global_currency ? (
                              <CanvasJSChart options={Options} />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : null}
                      </div>
                      <div class="home_driver_sec">
                        <div class="sec_title">
                          <h2 class="head_sty1">Drivers</h2>
                          {LoadGoogleMap()}
                        </div>
                      </div>
                      <Modal
                        class="modalform"
                        size="s"
                        class="modal-dialog modal-dialog-centered modal-lg"
                        style={{ "padding-top": "150px" }}
                        fullscreen="true"
                        show={edit_order}
                        onHide={handleEditOrderClose}
                      >
                        <OrdersEdit
                          values={{
                            orderId: orderId,
                            order_detail_id: order_detail_id,
                            product_image: product_image,
                            product_name: product_name,
                            product_price: product_price,
                            decrementQuantity: decrementQuantity,
                            product_quantity: product_quantity,
                            incrementQuantity: incrementQuantity,
                            updateOrderQuantity: updateOrderQuantity,
                          }}
                        />
                      </Modal>
                      <Modal
                        class="modalform"
                        size="s"
                        class="modal-dialog modal-dialog-centered modal-lg"
                        style={{ "padding-top": "150px" }}
                        fullscreen="true"
                        show={assign_driver}
                        onHide={handleAssignDriverClose}
                      >
                        <div class="modal-content">
                          <Modal.Header closeButton>
                            <Modal.Title>Available Drivers</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <OrdersAvailableDrivers
                              drivers={drivers_data}
                              handleCheckbox={handleDriverCheckbox}
                            />
                          </Modal.Body>
                          <Modal.Footer>
                            <div class="col-md-12">
                              <div class="form-group mb-0 staff_profile_btn_group">
                                <button
                                  type="submit"
                                  class="btn_sty_1"
                                  style={{ width: "110px;" }}
                                  onClick={() => assignOrders()}
                                >
                                  Assign Orders
                                </button>
                              </div>
                            </div>
                          </Modal.Footer>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
                <script
                  type="text/javascript"
                  src="https://maps.googleapis.com/maps/api/js?sensor=false"
                ></script>
                <script
                  type="text/javascript"
                  src="js/jquery-1.8.3.min.js"
                ></script>
                <script
                  src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
                  integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
                  crossorigin="anonymous"
                ></script>
                <script
                  src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
                  integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
                  crossorigin="anonymous"
                ></script>
                <script
                  src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
                  integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
                  crossorigin="anonymous"
                ></script>
                <script src="js/jquery.mCustomScrollbar.concat.min.js"></script>
                <script></script>
              </div>
            </div>
          </div>
        </body>
      </html>
    </div>
  );
};
export default Home;
